import React from "react";
import { useEffect } from "react";
import Layout from "../../../components/Documentation/Layout";
import Content from "../../../components/Documentation/Contents/AlphaChannel";
import { useAuth } from "../../../contexts/AuthContext/AuthContext";
import { useSecondaryNavbar } from "../../../components/SecondaryNavbarContext";
import SEOHead from "../../../components/SEOHead";
import {
  AuthContent,
  UnauthContent,
} from "../../../components/SecondaryNavbars/ApiKeyAndCredits";

const AlphaChannel: React.FC = () => {
  const { isAuthenticated, credits, apiKey, expiryDate } = useAuth();
  const { setContent } = useSecondaryNavbar();

  useEffect(() => {
    if (isAuthenticated) {
      setContent(
        <AuthContent
          credits={credits}
          apiKey={apiKey}
          expiryDate={expiryDate}
        />
      ); // Use AuthContent with credits prop
    } else {
      setContent(<UnauthContent />); // Use UnauthContent
    }
    return () => {
      setContent(null); // Clean up when component unmounts
    };
  }, [setContent, isAuthenticated, credits]);

  return (
    <>

      <SEOHead
        title={"Docs: Alpha Channel"}
        description={"Extract image alpha channels as grayscale. Use for background removal, blurring, or custom effects. Get PNG or base64 JSON output"}
        imageUrl="https://withoutbg.com/api-documentation-og.png"
      />
      <Layout>
        <Content />
      </Layout>
    </>
  );
};

export default AlphaChannel;
