import React, { useEffect, useState } from "react";
import { useTheme } from "../contexts/ThemeContext";
import { Sparkles, Percent, Zap } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useScrollToElement from "../hooks/useScrollToElement";
import { Link } from "react-router-dom";
import { useLocalizedNavigation } from "../hooks/useLocalizedNavigation";

const HeroSection: React.FC = () => {
  const { theme } = useTheme();
  const { t } = useTranslation("hero");
  const [videoSrc, setVideoSrc] = useState("");
  const navigate = useNavigate();
  const scrollToElement = useScrollToElement();
  const { createLocalizedLink } = useLocalizedNavigation();

  const [isSignUpOpen, setIsSignUpOpen] = React.useState(false);
  const [isLoginOpen, setIsLoginOpen] = React.useState(false);

  const openSignUp = () => {
    setIsSignUpOpen(true);
    setIsLoginOpen(false);
  };

  useEffect(() => {
    setVideoSrc(
      theme === "dark"
        ? "https://media.withoutbg.com/video/api-illustration-dark-bg.mp4"
        : "https://media.withoutbg.com/video/api-illustration-light-bg.mp4"
    );
  }, [theme]);

  const handleTryNowClick = () => {
    navigate("/remove-background");
    scrollToElement("editor");
  };

  const handlePricingClick = () => {
    scrollToElement("pricing");
  };

  // Updated button base style with fixed width and nowrap
  const buttonBaseStyle =
    "font-bold py-2 px-6 rounded transition duration-300 flex items-center justify-center whitespace-nowrap min-w-[160px] h-12";

  return (
    <div className="bg-lighter dark:bg-darker transition-colors duration-200 py-16 min-h-[700px] flex items-center">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 w-full">
        <div className="flex flex-col md:flex-row items-center justify-between">
          <div className="w-full md:w-1/2 mb-8 md:mb-0">
            <h1 className="font-bold mb-4 text-purple-900 dark:text-purple-100 text-3xl">
              {t("title")}
            </h1>

            <p className="text-gray-600 dark:text-gray-300 mb-6 text-2xl">
              {t("subtitle")}
            </p>
            <ul className="text-gray-600 dark:text-gray-300 mb-6 space-y-3">
              <li className="flex items-start">
                <Sparkles className="w-5 h-5 mr-2 text-purple-600 mt-1 flex-shrink-0" />
                <span>
                  <strong>{t("features.quality.title")}</strong>
                  <br />
                  <span className="text-sm italic">
                    {t("features.quality.description")}
                  </span>
                </span>
              </li>
              <li className="flex items-start">
                <Percent className="w-5 h-5 mr-2 text-purple-600 mt-1 flex-shrink-0" />
                <span>
                  <strong>{t("features.pricing.title")}</strong>
                  <br />
                  <span className="text-sm italic">
                    {t("features.pricing.description")}
                  </span>
                </span>
              </li>
              <li className="flex items-start">
                <Zap className="w-5 h-5 mr-2 text-purple-600 mt-1 flex-shrink-0" />
                <span>
                  <strong>{t("features.speed.title")}</strong>
                  <br />
                  <span className="text-sm italic">
                    {t("features.speed.description")}
                  </span>
                </span>
              </li>
            </ul>

            <div className="flex flex-col sm:flex-row gap-3">
              <Link
                to={createLocalizedLink("/signup")}
                className={`${buttonBaseStyle} bg-orange-700 hover:bg-orange-800 text-white`}
              >
                {t("buttons.getApiKey")}
              </Link>
              <Link
                to={createLocalizedLink("/remove-background")}
                className={`${buttonBaseStyle} bg-purple-300 hover:bg-purple-400 text-gray-800`}
              >
                {t("buttons.instantDemo")}
              </Link>
              <button
                onClick={handlePricingClick}
                className={`${buttonBaseStyle} bg-transparent hover:bg-purple-100 text-purple-700 border-2 border-purple-700 dark:border-purple-200 dark:text-purple-200 dark:hover:bg-gray-700`}
              >
                {t("buttons.viewPricing")}
              </button>
            </div>
          </div>
          <div className="w-full md:w-1/2 hidden md:block">
            <div className="relative w-full h-0 pb-[56.25%] rounded-lg overflow-hidden">
              {videoSrc && (
                <video
                  key={videoSrc}
                  className="absolute top-0 left-0 w-full h-full object-cover"
                  autoPlay
                  loop
                  muted
                  playsInline
                >
                  <source src={videoSrc} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
