import React from "react";

interface AlertProps {
  variant?: "default" | "destructive";
  children: React.ReactNode;
}

const Alert: React.FC<AlertProps> = ({ variant = "default", children }) => {
  const baseClasses = "p-4 rounded-md mb-4";
  const variantClasses = {
    default: "bg-blue-100 text-blue-700 border border-blue-200",
    destructive: "bg-red-100 text-red-700 border border-red-200",
  };

  return (
    <div className={`${baseClasses} ${variantClasses[variant]}`}>
      {children}
    </div>
  );
};

export const AlertTitle: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => <h5 className="font-medium mb-1">{children}</h5>;

export const AlertDescription: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => <p className="text-sm">{children}</p>;

export default Alert;
