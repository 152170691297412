import React from "react";
import { useTranslation } from "react-i18next";

interface StaticDigitalCounterProps {
  credits: number;
  expiryDate: string;
  className?: string;
}

const StaticDigitalCounter: React.FC<StaticDigitalCounterProps> = ({
  credits,
  expiryDate,
  className = "",
}) => {
  const getDigits = (number: number): string[] => {
    return number.toString().padStart(5, "0").split("");
  };
  const { t } = useTranslation("account");

  return (
    <div className={`${className}`}>
      <div className="flex flex-col items-center space-y-4">
        <div className="text-sm font-medium text-gray-600 dark:text-gray-400">
          {t("apiKey.creditsRemaining")}
        </div>

        <div className="flex space-x-1">
          {getDigits(credits).map((digit, index) => (
            <div
              key={index}
              className="bg-gray-200 dark:bg-gray-800 rounded-lg p-2 w-12 h-16 flex items-center justify-center"
            >
              <span className="font-mono text-3xl font-bold bg-gradient-to-b from-green-500 to-green-700 dark:from-green-400 dark:to-green-600 bg-clip-text text-transparent">
                {digit}
              </span>
            </div>
          ))}
        </div>

        <div className="flex flex-col items-center space-y-1">
          <div className="text-xs text-gray-600 dark:text-gray-400">
            {t("apiKey.expires")}
          </div>
          <div className="flex items-center space-x-1">
            <div className="bg-gray-200 dark:bg-gray-800 rounded-lg px-4 py-2">
              <span className="font-mono text-sm font-semibold text-gray-600 dark:text-gray-400">
                {expiryDate}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaticDigitalCounter;
