import React, { useState, useEffect } from "react";
import { Sun, Moon, Menu, X, LogOut } from "lucide-react";
import { useTheme } from "../contexts/ThemeContext";
import { Link, useLocation } from "react-router-dom";
import ContactPopover from "./Contact/ContactPopover";
import { useAuth } from "../contexts/AuthContext/AuthContext";
import LanguageSwitcher from "./LanguageSwitcher";
import { useTranslation } from "react-i18next";
import { useLocalizedNavigation } from "../hooks/useLocalizedNavigation";

interface NavbarProps {
  onLoginClick: () => void;
}

const Navbar: React.FC<NavbarProps> = ({ onLoginClick }) => {
  const { theme, toggleTheme } = useTheme();
  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);
  const [isContactOpen, setIsContactOpen] = useState(false);
  const location = useLocation();
  const { isAuthenticated, logout, userEmail } = useAuth();
  const { t } = useTranslation("navbar");
  const { createLocalizedLink, navigateLocalized } = useLocalizedNavigation();

  const toggleOffcanvas = () => {
    setIsOffcanvasOpen(!isOffcanvasOpen);
  };

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    const hash = location.hash.replace("#", "");
    if (hash) {
      setTimeout(() => {
        scrollToSection(hash);
      }, 100);
    }
  }, [location.pathname]);

  const handleNavigation = (item: {
    id?: string;
    to?: string;
    href?: string;
  }) => {
    if (item.id) {
      if (
        location.pathname === "/" ||
        location.pathname === createLocalizedLink("/")
      ) {
        scrollToSection(item.id);
      } else {
        navigateLocalized(`/#${item.id}`);
      }
    } else if (item.to) {
      navigateLocalized(item.to);
    } else if (item.href) {
      window.location.href = item.href;
    }
    setIsOffcanvasOpen(false);
  };

  const handleContactClick = () => {
    setIsContactOpen(true);
    setIsOffcanvasOpen(false);
  };

  const handleLogout = () => {
    logout();
    setIsOffcanvasOpen(false);
    navigateLocalized("/");
  };

  const navItems = [
    { to: "/remove-background", text: t("try") },
    { id: "pricing", text: t("pricing") },
    { to: "/documentation", text: t("docs") },
    { to: "/resources", text: t("resources") },
    { text: t("contact"), onClick: handleContactClick },
  ];

  return (
    <>
      <nav className="bg-purple-700 text-white fixed top-0 left-0 right-0 z-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-12">
            <div className="flex items-center">
              <Link to={createLocalizedLink("/")} className="flex-shrink-0">
                <img
                  className="h-6 w-auto"
                  src="https://media.withoutbg.com/logo-light.png"
                  alt={t("logo.alt")}
                />
              </Link>
              <div className="hidden md:block">
                <div className="ml-10 flex items-baseline space-x-2">
                  {navItems.map((item) =>
                    item.to ? (
                      <Link
                        key={item.text}
                        to={createLocalizedLink(item.to)}
                        className="px-2 py-1 rounded-md text-sm font-medium hover:bg-purple-600"
                      >
                        {item.text}
                      </Link>
                    ) : (
                      <button
                        key={item.text}
                        onClick={() =>
                          item.onClick ? item.onClick() : handleNavigation(item)
                        }
                        className="px-2 py-1 rounded-md text-sm font-medium hover:bg-purple-600"
                      >
                        {item.text}
                      </button>
                    )
                  )}
                </div>
              </div>
            </div>
            <div className="flex items-center space-x-2">
              {isAuthenticated ? (
                <>
                  <Link
                    to={createLocalizedLink("/account")}
                    className="px-2 py-1 rounded-md text-sm font-medium bg-purple-600 hover:bg-purple-500"
                  >
                    {userEmail}
                  </Link>
                  <button
                    onClick={handleLogout}
                    className="p-2 rounded-full hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-purple-800 focus:ring-white"
                    title={t("logout")}
                  >
                    <LogOut size={20} />
                  </button>
                </>
              ) : (
                <>
                  <button
                    onClick={onLoginClick}
                    className="px-2 py-1 rounded-md text-sm font-medium bg-gray-800 hover:bg-gray-900"
                  >
                    {t("login")}
                  </button>
                  <Link
                    to={createLocalizedLink("/signup")}
                    className="px-2 py-1 rounded-md text-sm font-medium bg-orange-600 hover:bg-orange-500"
                  >
                    {t("signup")}
                  </Link>
                </>
              )}
              <LanguageSwitcher />
              <button
                onClick={toggleTheme}
                className="hidden md:block p-2 rounded-full hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-purple-800 focus:ring-white"
                title={t(theme === "light" ? "switchToDark" : "switchToLight")}
              >
                {theme === "light" ? <Moon size={20} /> : <Sun size={20} />}
              </button>
              <button
                onClick={toggleOffcanvas}
                className="md:hidden p-2 rounded-full hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-purple-800 focus:ring-white"
                title={t("menu")}
              >
                <Menu size={24} />
              </button>
            </div>
          </div>
        </div>

        {/* Off-canvas menu */}
        <div
          className={`fixed inset-y-0 right-0 w-64 bg-purple-800 transform ${
            isOffcanvasOpen ? "translate-x-0" : "translate-x-full"
          } transition-transform duration-300 ease-in-out z-50 md:hidden`}
        >
          <div className="p-6">
            <button
              onClick={toggleOffcanvas}
              className="absolute top-4 right-4 text-white hover:text-gray-300"
              title={t("closeMenu")}
            >
              <X size={24} />
            </button>
            <div className="mt-8 space-y-4">
              {navItems.map((item) =>
                item.to ? (
                  <Link
                    key={item.text}
                    to={createLocalizedLink(item.to)}
                    className="block w-full text-left px-3 py-2 rounded-md text-base font-medium text-white hover:bg-purple-700"
                    onClick={toggleOffcanvas}
                  >
                    {item.text}
                  </Link>
                ) : (
                  <button
                    key={item.text}
                    onClick={() =>
                      item.onClick ? item.onClick() : handleNavigation(item)
                    }
                    className="block w-full text-left px-3 py-2 rounded-md text-base font-medium text-white hover:bg-purple-700"
                  >
                    {item.text}
                  </button>
                )
              )}
              <button
                onClick={toggleTheme}
                className="block w-full text-left px-3 py-2 rounded-md text-base font-medium text-white hover:bg-purple-700"
              >
                {theme === "light" ? t("switchToDark") : t("switchToLight")}
              </button>
            </div>
          </div>
        </div>

        <ContactPopover
          isOpen={isContactOpen}
          onClose={() => setIsContactOpen(false)}
        />
      </nav>
    </>
  );
};

export default Navbar;
