import { ComparisonSlider } from "react-comparison-slider";
import { MoveHorizontal } from "lucide-react";

const BeforeAfterSlider: React.FC<{
  before: string;
  after: string;
  alt: string;
  aspectRatio: number;
}> = ({ before, after, alt, aspectRatio }) => {
  return (
    <div className="rounded-lg overflow-hidden">
      <ComparisonSlider
        defaultValue={50}
        itemOne={
          <img
            src={before}
            alt={`${alt} - Before`}
            className="w-full h-full object-cover"
          />
        }
        itemTwo={
          <div className="w-full h-full bg-checkerboard bg-checkerboard-size bg-checkerboard-repeat">
            <img
              src={after}
              alt={`${alt} - After`}
              className="w-full h-full object-cover"
            />
          </div>
        }
        aspectRatio={aspectRatio}
        handle={({ isFocused }) => (
          <div
            className={`rounded-full w-10 h-10 bg-white text-gray-600 flex items-center justify-center shadow-md ${
              isFocused ? "ring ring-blue-500 ring-opacity-50" : ""
            }`}
          >
            <MoveHorizontal size={24} />
          </div>
        )}
      />
    </div>
  );
};

export default BeforeAfterSlider;
