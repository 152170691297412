import React from "react";
import { Link } from "react-router-dom";
import { ChevronRight } from "lucide-react";
import { useLocalizedNavigation } from "../../hooks/useLocalizedNavigation";

interface BreadcrumbItem {
  label: string;
  path: string;
}

interface BreadcrumbProps {
  items: BreadcrumbItem[];
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ items }) => {
  const { createLocalizedLink } = useLocalizedNavigation();

  return (
    <nav className="flex mb-4" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-2">
        {items.map((item, index) => (
          <li key={index} className="flex items-center">
            {index > 0 && (
              <ChevronRight className="w-4 h-4 text-gray-400 mx-2" />
            )}
            {index === items.length - 1 ? (
              <span className="text-sm font-semibold text-gray-900 dark:text-gray-100">
                {item.label}
              </span>
            ) : (
              <Link
                to={createLocalizedLink(item.path)}
                className="text-sm font-medium text-gray-400 hover:text-purple-800 dark:hover:text-purple-200 transition-colors duration-200"
              >
                {item.label}
              </Link>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
