import React from "react";
import Sidebar from "./Sidebar";

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen bg-white dark:bg-gray-900 text-gray-900 dark:text-white">
      <div className="flex-grow pt-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex">
          <Sidebar />
          <main className="flex-grow w-full lg:pl-64">
            <div className="py-8">{children}</div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default Layout;
