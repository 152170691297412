import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import config from "../config";
import { useAuth } from "../contexts/AuthContext/AuthContext";
import { motion, useAnimation } from "framer-motion";
import SEOHead from "../components/SEOHead";

interface PaymentSuccessData {
  message: string;
  user_email: string;
  api_key: string;
  expiration_date: string;
  credits: number;
  previous_credits: number;
  previous_expiration_date: string;
}

interface PaymentSuccessProps {
  onLoginClick: () => void;
}

const useCounter = (start: number, end: number, duration: number) => {
  const [count, setCount] = useState(start);
  const [isCountingComplete, setIsCountingComplete] = useState(false);
  const [digits, setDigits] = useState<string[]>([]);

  useEffect(() => {
    let startTime: number;
    const animateCount = (timestamp: number) => {
      if (!startTime) startTime = timestamp;
      const progress = Math.min((timestamp - startTime) / duration, 1);
      const currentCount = Math.floor(progress * (end - start) + start);
      setCount(currentCount);
      setDigits(currentCount.toString().padStart(5, "0").split(""));

      if (progress < 1) {
        requestAnimationFrame(animateCount);
      } else {
        setIsCountingComplete(true);
      }
    };
    requestAnimationFrame(animateCount);
  }, [start, end, duration]);

  return { count, isCountingComplete, digits };
};

const DigitDisplay = ({
  digit,
  animate,
}: {
  digit: string;
  animate: boolean;
}) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
    className="bg-gray-200 dark:bg-gray-800 rounded-lg p-2 w-12 h-16 flex items-center justify-center"
  >
    <motion.span
      animate={animate ? { scale: [1, 1.2, 1] } : {}}
      transition={{ duration: 0.3 }}
      className="font-mono text-3xl font-bold bg-gradient-to-b from-green-500 to-green-700 dark:from-green-400 dark:to-green-600 bg-clip-text text-transparent"
    >
      {digit}
    </motion.span>
  </motion.div>
);

const PaymentSuccess: React.FC<PaymentSuccessProps> = ({ onLoginClick }) => {
  const [userData, setUserData] = useState<PaymentSuccessData | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [startCount, setStartCount] = useState(0);
  const [endCount, setEndCount] = useState(0);
  const controls = useAnimation();
  const location = useLocation();

  const {
    count: creditCount,
    isCountingComplete,
    digits,
  } = useCounter(startCount, endCount, 2000);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("access_token");
        if (!token) {
          setError("No authentication token found. Please log in.");
          return;
        }

        const pathParts = location.pathname.split("/");
        const purchaseId = pathParts[pathParts.length - 1];

        if (!purchaseId) {
          setError("Purchase ID not found in URL.");
          return;
        }

        const response = await fetch(
          `${config.API_URL}/payment/success/${purchaseId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch user data");
        }

        const data: PaymentSuccessData = await response.json();
        setUserData(data);
        setStartCount(data.previous_credits);
        setEndCount(data.credits);
        controls.start({ opacity: 1, y: 0 });
      } catch (err) {
        setError("Failed to fetch user data. Please try again later.");
        console.error("Error fetching user data:", err);
      }
    };

    fetchUserData();
  }, [location.pathname, controls]);

  return (
    <>


      <SEOHead
        title={"Payment Success"}
        description={"Payment successful. View your updated account information."}
        imageUrl="https://withoutbg.com/api-documentation-og.png"
      />
      <div className="bg-gray-100 dark:bg-gray-900 min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        {error ? (
          <p className="text-red-500 dark:text-red-400">{error}</p>
        ) : userData ? (
          <div className="flex flex-col items-center space-y-8">
            <motion.div
              className="flex flex-col items-center space-y-4"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <div className="text-sm font-medium text-gray-600 dark:text-gray-400">
                Credits Remaining
              </div>

              <div className="flex space-x-1">
                {digits.map((digit, index) => (
                  <DigitDisplay
                    key={index}
                    digit={digit}
                    animate={!isCountingComplete}
                  />
                ))}
              </div>
            </motion.div>

            <motion.p
              className="text-l text-purple-600 dark:text-purple-400"
              initial={{ opacity: 0, y: 20 }}
              animate={{
                opacity: isCountingComplete ? 1 : 0,
                y: isCountingComplete ? 0 : 20,
              }}
              transition={{ delay: 2, duration: 0.5 }}
            >
              Awesome! Your account is powered up!
            </motion.p>

            <motion.div
              className="w-full max-w-xs"
              initial={{ opacity: 0 }}
              animate={{ opacity: isCountingComplete ? 1 : 0 }}
              transition={{ delay: 3, duration: 0.5 }}
            >
              <Link
                to="/account"
                className="block w-full bg-purple-600 hover:bg-purple-700 text-white text-center py-2 rounded-md transition duration-300"
              >
                Go to Dashboard
              </Link>
            </motion.div>
          </div>
        ) : (
          <div className="text-center text-gray-800 dark:text-gray-200">
            <p className="text-xl mb-4">You need to login to see this page.</p>
            <button
              onClick={onLoginClick}
              className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded transition duration-300"
            >
              Get a new login link
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default PaymentSuccess;
