import React, { useState, useEffect, useRef } from "react";
import { Copy, Check, ChevronDown } from "lucide-react";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import js from "react-syntax-highlighter/dist/esm/languages/hljs/javascript";
import python from "react-syntax-highlighter/dist/esm/languages/hljs/python";
import bash from "react-syntax-highlighter/dist/esm/languages/hljs/bash";
import go from "react-syntax-highlighter/dist/esm/languages/hljs/go";
import php from "react-syntax-highlighter/dist/esm/languages/hljs/php";
import ruby from "react-syntax-highlighter/dist/esm/languages/hljs/ruby";
import java from "react-syntax-highlighter/dist/esm/languages/hljs/java";

import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";

SyntaxHighlighter.registerLanguage("javascript", js);
SyntaxHighlighter.registerLanguage("python", python);
SyntaxHighlighter.registerLanguage("bash", bash);
SyntaxHighlighter.registerLanguage("go", go);
SyntaxHighlighter.registerLanguage("php", php);
SyntaxHighlighter.registerLanguage("ruby", ruby);
SyntaxHighlighter.registerLanguage("java", java);

interface Script {
  name: string;
  language: string;
  content: string;
}

interface CodeWindowProps {
  scripts: Script[];
  method: string;
  target: string;
  selectedScript: Script;
  onScriptChange: (scriptName: string) => void;
}

const CodeWindow: React.FC<CodeWindowProps> = ({
  scripts,
  method,
  target,
  selectedScript,
  onScriptChange,
}) => {
  const [copied, setCopied] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const getMethodColor = (method: string) => {
    switch (method.toUpperCase()) {
      case "POST":
        return "text-green-600 dark:text-green-400";
      case "GET":
        return "text-blue-400 dark:text-blue-400";
      default:
        return "text-gray-600 dark:text-gray-400";
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(selectedScript.content);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const codeHeight = 20 * 24; // Adjust this value as needed

  return (
    <div className="rounded-lg overflow-hidden shadow-lg bg-gray-100 dark:bg-gray-800 text-gray-800 dark:text-white">
      <div className="flex justify-between items-center bg-gray-800 dark:bg-gray-700 px-4 py-2">
        <div className="flex items-center space-x-2">
          <span
            className={`text-sm font-mono font-semibold ${getMethodColor(
              method
            )}`}
          >
            {method}
          </span>
          <span className="text-sm font-mono font-bold text-gray-100">
            {target}
          </span>
        </div>
        <div className="flex items-center space-x-2">
          <div className="relative" ref={dropdownRef}>
            <button
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              className="flex items-center space-x-1 bg-gray-800 dark:bg-gray-700 hover:bg-gray-700 dark:hover:bg-gray-500 text-gray-800 text-white dark:text-white rounded px-3 py-1 text-sm"
            >
              <span>{selectedScript.name}</span>
              <ChevronDown size={14} />
            </button>
            {isDropdownOpen && (
              <div className="absolute right-0 mt-1 w-40 bg-white dark:bg-gray-600 rounded-md shadow-lg z-10">
                {scripts.map((script) => (
                  <button
                    key={script.name}
                    className="flex justify-between items-center w-full text-left px-4 py-2 text-sm text-gray-800 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-500"
                    onClick={() => {
                      onScriptChange(script.name);
                      setIsDropdownOpen(false);
                    }}
                  >
                    <span>{script.name}</span>
                    {script.name === selectedScript.name && <Check size={14} />}
                  </button>
                ))}
              </div>
            )}
          </div>
          <button
            onClick={copyToClipboard}
            className={`text-gray-300 dark:text-gray-300 hover:text-gray-700 dark:hover:text-white transition-colors duration-200 ${
              copied ? "text-green-500 dark:text-green-400" : ""
            }`}
            title="Copy to clipboard"
          >
            {copied ? <Check size={18} /> : <Copy size={18} />}
          </button>
        </div>
      </div>
      <div style={{ height: `${codeHeight}px`, overflow: "auto" }}>
        <SyntaxHighlighter
          language={selectedScript.language.toLowerCase()}
          style={atomOneDark}
          customStyle={{
            margin: 0,
            padding: "1rem",
            height: "100%",
            backgroundColor: "#2c314d",
            fontWeight: 400,
          }}
        >
          {selectedScript.content}
        </SyntaxHighlighter>
      </div>
    </div>
  );
};

export default CodeWindow;
