import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const useLocalizedNavigation = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const getLanguagePrefix = () => {
    return i18n.language === "en" ? "" : `/${i18n.language}`;
  };

  const createLocalizedLink = (to: string) => {
    return `${getLanguagePrefix()}${to}`;
  };

  const navigateLocalized = (to: string) => {
    navigate(createLocalizedLink(to));
  };

  return {
    getLanguagePrefix,
    createLocalizedLink,
    navigateLocalized,
    currentLanguage: i18n.language,
  };
};
