import React, { useState, useEffect } from "react";
import TopActionBar from "./TopActionBar";
import Canvas from "./Canvas";
import PredefinedImages from "./PredefinedImages";
import Loader from "./Loader";
import BeforeAfterComparison from "./BeforeAfter";
import { useImageProcessor } from "./useImageProcessor";
import BackgroundImagePicker from "./BackgroundImgPicker";

interface EditorProps {
  showPredefinedImages?: boolean;
  showBottomActionBarAfterProcessing?: boolean;
}

const Editor: React.FC<EditorProps> = ({
  showPredefinedImages = false,
  showBottomActionBarAfterProcessing = true,
}) => {
  const [csrfToken, setCsrfToken] = useState<string | null>(null);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const { processImage, beforeImage, processedImage, loading, resetImages } =
    useImageProcessor(csrfToken, (url: string) => {});

  const [background, setBackground] = useState<
    "checkerboard" | "color" | "image"
  >("checkerboard");
  const [backgroundColor, setBackgroundColor] = useState("#FFFFFF");
  const [backgroundImage, setBackgroundImage] = useState<string | null>(null);
  const [isComparing, setIsComparing] = useState(false);
  const [showBefore, setShowBefore] = useState(false);
  const [showBackgroundPicker, setShowBackgroundPicker] = useState(false);

  const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleImageUpload = (file: File) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result as string;
      processImage(base64String);
    };
    reader.readAsDataURL(file);
  };

  const handleSelectImage = (url: string) => {
    processImage(url);
  };

  const handleBackgroundChange = (
    type: "checkerboard" | "color" | "image",
    value?: string
  ) => {
    setBackground(type);
    if (type === "color" && value) {
      setBackgroundColor(value);
    } else if (type === "image") {
      setShowBackgroundPicker(true);
    }
  };

  const handleBackgroundImageSelect = (imageUrl: string) => {
    setBackgroundImage(imageUrl);
    setBackground("image");
    setShowBackgroundPicker(false);
  };

  const handleDownload = () => {
    if (processedImage) {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");

        if (ctx) {
          if (background === "color") {
            ctx.fillStyle = backgroundColor;
            ctx.fillRect(0, 0, canvas.width, canvas.height);
          } else if (background === "image" && backgroundImage) {
            const bgImg = new Image();
            bgImg.onload = () => {
              ctx.drawImage(bgImg, 0, 0, canvas.width, canvas.height);
              ctx.drawImage(img, 0, 0);
              const dataUrl = canvas.toDataURL("image/png");
              initiateDownload(dataUrl);
            };
            bgImg.src = backgroundImage;
            return; // Exit early as we're handling the download in the onload callback
          }
          ctx.drawImage(img, 0, 0);
          const dataUrl = canvas.toDataURL("image/png");
          initiateDownload(dataUrl);
        }
      };
      img.src = processedImage;
    }
  };

  const handleCompareToggle = () => {
    setIsComparing(!isComparing);
  };

  const handleBeforeAfterChange = (showBefore: boolean) => {
    setShowBefore(showBefore);
  };

  const initiateDownload = (dataUrl: string) => {
    const link = document.createElement("a");
    link.href = dataUrl;
    link.download = "processed_image.png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleTryAnotherImage = () => {
    resetImages(); // Reset the images in useImageProcessor
    setIsComparing(false);
    setShowBefore(false);
    // Reset any other relevant state
  };

  return (
    <div className="w-full flex flex-col items-center">
      <div className="relative w-full max-w-2xl">
        {(beforeImage || processedImage) && (
          <TopActionBar
            onBackgroundChange={handleBackgroundChange}
            onTryAnotherImage={handleTryAnotherImage}
            onBeforeAfterChange={handleBeforeAfterChange}
            onDownload={handleDownload}
            showBefore={showBefore}
          />
        )}
        <div className="w-full">
          <div className="relative">
            {isComparing && beforeImage && processedImage ? (
              <BeforeAfterComparison
                beforeImage={beforeImage}
                afterImage={processedImage}
                background={background}
                backgroundColor={backgroundColor}
                backgroundImage={backgroundImage}
              />
            ) : (
              <>
                {!loading && (
                  <Canvas
                    imageUrl={showBefore ? beforeImage : processedImage}
                    isProcessed={!showBefore && !!processedImage}
                    onImageDrop={handleImageUpload}
                    background={background}
                    backgroundColor={backgroundColor}
                    backgroundImage={backgroundImage}
                    loading={loading}
                  />
                )}

                {loading && <Loader imageUrl={beforeImage || ""} />}
              </>
            )}
          </div>
          {/* Add a placeholder during loading to reserve space */}
          {loading && showBottomActionBarAfterProcessing && (
            <div className="h-16" />
          )}
        </div>
        {!beforeImage && !processedImage && showPredefinedImages && (
          <PredefinedImages onImageSelect={handleSelectImage} />
        )}
        {showBackgroundPicker && (
          <BackgroundImagePicker
            onClose={() => setShowBackgroundPicker(false)}
            onImageSelect={handleBackgroundImageSelect}
          />
        )}
      </div>
    </div>
  );
};

export default Editor;
