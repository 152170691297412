import React from "react";
import { useTranslation } from "react-i18next";

interface MessageInputProps {
  message: string;
  setMessage: (message: string) => void;
}

const MessageInput: React.FC<MessageInputProps> = ({ message, setMessage }) => {
  const { t } = useTranslation("components/contact");

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);
  };

  const isValidMessage = message.length >= 16;

  return (
    <div className="space-y-2 relative">
      <label
        htmlFor="message"
        className="block text-sm font-medium text-gray-700 dark:text-gray-300"
      >
        {t("label")}
      </label>
      <textarea
        id="message"
        name="message"
        rows={4}
        className={`w-full p-2 border rounded 
          bg-white dark:bg-gray-700 
          text-gray-900 dark:text-white 
          border-gray-300 dark:border-gray-600 
          placeholder-gray-500 dark:placeholder-gray-400
          focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-purple-500 
          transition-all duration-200 
          ${message.length > 0 && !isValidMessage ? "border-red-500" : ""}`}
        placeholder={t("messagePlaceholder")}
        value={message}
        onChange={handleChange}
        style={{ minHeight: "100px" }}
      />
      {!isValidMessage && (
        <p className="text-sm text-gray-600 dark:text-gray-400">
          {message.length} / 16 {t("minLength")}
        </p>
      )}
      {isValidMessage && (
        <svg
          className="absolute right-3 top-11 h-5 w-5 text-green-500"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path d="M5 13l4 4L19 7"></path>
        </svg>
      )}
    </div>
  );
};

export default MessageInput;
