import { Loader } from "lucide-react";
import { useTranslation } from "react-i18next";

interface CaptchaChallenge {
  images: { [key: string]: string };
  overlay: string;
  uuid: string;
}

interface Coordinate {
  x: number;
  y: number;
}

const CaptchaChallenge: React.FC<{
  captchaChallenge: CaptchaChallenge;
  currentImageIndex: number;
  isLoading: boolean;
  handleImageClick: (e: React.MouseEvent<HTMLImageElement>) => void;
  clickedCoordinates: Coordinate[];
}> = ({
  captchaChallenge,
  currentImageIndex,
  isLoading,
  handleImageClick,
  clickedCoordinates,
}) => {
  const { t } = useTranslation("signup");

  return (
    <div className="space-y-2 py-10">
      <p className="text-sm text-gray-600 dark:text-gray-400 text-center pb-3">
        {t("captchaDescription")}
      </p>
      {isLoading ? (
        <div className="flex justify-center items-center h-32">
          <Loader
            className="animate-spin text-purple-600 dark:text-purple-400"
            size={32}
          />
        </div>
      ) : (
        <div
          className="relative overflow-hidden cursor-crosshair mx-auto"
          style={{ width: "128px", height: "128px" }}
        >
          {Object.entries(captchaChallenge.images).map(
            ([key, image], index) => (
              <img
                key={key}
                src={`data:image/jpeg;base64,${image}`}
                alt={t("imageAlt", { index: index + 1 })}
                onClick={handleImageClick}
                className="absolute top-0 left-0 w-full h-full transition-opacity duration-300 ease-in-out"
                style={{
                  opacity: index === currentImageIndex ? 1 : 0,
                  pointerEvents: index === currentImageIndex ? "auto" : "none",
                }}
              />
            )
          )}
          {currentImageIndex === 0 && (
            <img
              src={`data:image/png;base64,${captchaChallenge.overlay}`}
              alt={t("overlayAlt")}
              className="absolute top-0 left-0 w-full h-full pointer-events-none animate-pulse"
            />
          )}
          {clickedCoordinates.map((coord, index) => (
            <div
              key={index}
              className="absolute w-3 h-3 bg-purple-500 rounded-full opacity-90 transform -translate-x-1/2 -translate-y-1/2"
              style={{
                left: coord.x,
                top: coord.y,
                display: index === currentImageIndex ? "block" : "none",
              }}
            />
          ))}
        </div>
      )}
      <div className="bg-gray-200 dark:bg-gray-700 h-1 rounded-full overflow-hidden">
        <div
          className="bg-purple-600 dark:bg-purple-400 h-full transition-all duration-300 ease-in-out"
          style={{ width: `${clickedCoordinates.length * 20}%` }}
        ></div>
      </div>
    </div>
  );
};

export default CaptchaChallenge;
