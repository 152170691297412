import React, { useState, useEffect } from "react";
import { validateEmail } from "../../utils/validators";
import { useAuth } from "../../contexts/AuthContext/AuthContext";
import { useTranslation } from "react-i18next";

interface EmailInputProps {
  email: string;
  setEmail: (email: string) => void;
}

const EmailInput: React.FC<EmailInputProps> = ({ email, setEmail }) => {
  const { t } = useTranslation("common");
  const { isAuthenticated, userEmail } = useAuth();
  const [showWarning, setShowWarning] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const isValidEmail = validateEmail(isAuthenticated ? userEmail : email);

  useEffect(() => {
    if (isAuthenticated && userEmail) {
      setEmail(userEmail);
    }
  }, [isAuthenticated, userEmail, setEmail]);

  const handleBlur = () => {
    setIsFocused(false);
    if (!isAuthenticated && email && !isValidEmail) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isAuthenticated) {
      setEmail(e.target.value);
      setShowWarning(false);
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  return (
    <div className="relative">
      <input
        type="email"
        value={isAuthenticated ? userEmail : email}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        placeholder={t("emailField.emailPlaceholder")}
        className={`w-full p-2 border rounded 
          bg-white dark:bg-gray-700 
          text-gray-900 dark:text-white 
          border-gray-300 dark:border-gray-600 
          placeholder-gray-500 dark:placeholder-gray-400
          focus:outline-none focus:ring-2 
          transition-all duration-200 
          ${showWarning ? "border-red-500" : ""}
          ${
            isAuthenticated
              ? "bg-gray-100 dark:bg-gray-600 cursor-not-allowed"
              : ""
          }
          ${
            isFocused
              ? isValidEmail
                ? "focus:ring-green-500 focus:border-green-500"
                : "focus:ring-purple-500 focus:border-purple-500"
              : ""
          }`}
        required
        disabled={isAuthenticated}
      />
      {showWarning && (
        <p className="text-red-500 text-xs mt-1">
          {t("emailField.invalidEmail")}
        </p>
      )}
      {((isAuthenticated && userEmail) ||
        (!isAuthenticated && email && isValidEmail)) && (
        <svg
          className="absolute right-3 top-3 h-5 w-5 text-green-500"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path d="M5 13l4 4L19 7"></path>
        </svg>
      )}
    </div>
  );
};

export default EmailInput;
