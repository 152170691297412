import React from "react";
import { CheckCircle } from "lucide-react";
import { useTranslation } from "react-i18next";

interface RegistrationTimelineProps {
  align?: "left" | "right";
  step: number;
}

const RegistrationTimeline: React.FC<RegistrationTimelineProps> = ({
  align = "left",
  step,
}) => {
  const { t } = useTranslation("signup");
  const isRightAligned = align === "right";

  const steps = [
    {
      title: t("steps.step1.title"),
      description: t("steps.step1.description"),
    },
    {
      title: t("steps.step2.title"),
      description: t("steps.step2.description"),
    },
    {
      title: t("steps.step3.title"),
      description: t("steps.step3.description"),
    },
  ];

  return (
    <div className="max-w-md mx-auto mt-10 mx-10">
      <ol
        className={`relative border-l md:border-l-0 ${
          isRightAligned ? "md:border-r" : "md:border-l"
        } border-gray-200 dark:border-gray-700`}
      >
        {steps.map((stepItem, index) => {
          const isCompleted = index + 1 < step;
          const isCurrent = index + 1 === step;

          return (
            <li
              key={index}
              className={`mb-10 ml-6 md:ml-0 ${
                isRightAligned ? "md:mr-6 md:text-right" : "md:ml-6"
              }`}
            >
              <span
                className={`absolute flex items-center justify-center w-8 h-8 rounded-full -left-4 md:left-auto ${
                  isRightAligned ? "md:-right-4" : "md:-left-4"
                } ring-4 ring-white dark:ring-gray-800 ${
                  isCompleted
                    ? "bg-green-200 dark:bg-green-900"
                    : isCurrent
                    ? "bg-blue-200 dark:bg-blue-900"
                    : "bg-gray-100 dark:bg-gray-700"
                }`}
              >
                {isCompleted ? (
                  <CheckCircle className="w-5 h-5 text-green-500 dark:text-green-300" />
                ) : (
                  <span
                    className={`${
                      isCompleted
                        ? "text-green-500 dark:text-green-300"
                        : isCurrent
                        ? "text-blue-500 dark:text-blue-300"
                        : "text-gray-500 dark:text-gray-400"
                    }`}
                  >
                    {index + 1}
                  </span>
                )}
              </span>
              <h3
                className={`font-medium leading-tight ${
                  isCompleted
                    ? "text-green-500 dark:text-green-300"
                    : isCurrent
                    ? "text-blue-500 dark:text-blue-300"
                    : "text-gray-900 dark:text-gray-100"
                }`}
              >
                {stepItem.title}
              </h3>
              <p
                className={`text-sm ${
                  isCompleted
                    ? "text-green-500 dark:text-green-300"
                    : isCurrent
                    ? "text-blue-500 dark:text-blue-300"
                    : "text-gray-500 dark:text-gray-400"
                }`}
              >
                {stepItem.description}
              </p>
            </li>
          );
        })}
      </ol>
    </div>
  );
};

export default RegistrationTimeline;
