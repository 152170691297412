import React from "react";
import { useTranslation } from 'react-i18next';

const Privacy: React.FC = () => {
  const { t } = useTranslation('components/privacy');

  return (
    <div className="w-full bg-light dark:bg-dark text-gray-800 dark:text-gray-300">
      <div className="max-w-7xl mx-auto pt-8 pb-12 px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold mb-4 text-center text-gray-900 dark:text-white">
          {t('title')}
        </h2>
        <p className="text-center mb-6 text-gray-600 dark:text-gray-400">
          {t('subtitle')}
        </p>
        <ul className="list-disc pl-6 space-y-2 mx-auto text-gray-700 dark:text-gray-300">
          {[0, 1, 2].map((index) => (
            <li key={index}>
              {t(`features.${index}`)}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Privacy;