const config = {
  API_URL: process.env.REACT_APP_API_URL || "https://api.withoutbg.com",
  // API_URL: process.env.REACT_APP_API_URL || "http://localhost:81",

  STRIPE_PUBLISHABLE_KEY:
    "pk_live_51Ladi6DZ1QYUF7H5Xb2LZSvsJyueCiMcsdoSeJ1VWhGJWNCcLi9CNkSDUNfza0uEZxB9GGexq7FWJMrGQks8ebQd00Bs9zO5w4",
  //"pk_test_51Ladi6DZ1QYUF7H5Wgqc6woeVzTKbekKGtEpXHQZWKWH648bSor5hIY0LsyKz9EjLZ4WekM6eaF5XpP8mbj5gsfb004DrLvkxy",
};

export default config;
