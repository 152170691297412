import React from "react";
import { useTranslation } from 'react-i18next';

const predefinedImages = [
  {
    thumbnail: "thumbnail-joe-caione-qO-PIF84Vxg-unsplash.jpg",
    fullImage: "joe-caione-qO-PIF84Vxg-unsplash.jpg",
  },
  {
    thumbnail: "thumbnail-joshua-hoehne-7XLsFy0p3Ao-unsplash.jpg",
    fullImage: "joshua-hoehne-7XLsFy0p3Ao-unsplash.jpg",
  },
  {
    thumbnail: "thumbnail-benjamin-hershey-qegSxvH9U9Y-unsplash.jpg",
    fullImage: "benjamin-hershey-qegSxvH9U9Y-unsplash.jpg",
  },
  {
    thumbnail: "thumbnail-quino-al-iRt9yOWzfOk-unsplash.jpg",
    fullImage: "quino-al-iRt9yOWzfOk-unsplash.jpg",
  },
  {
    thumbnail: "thumbnail-kenny-eliason-6G7rmG8pDCs-unsplash.jpg",
    fullImage: "kenny-eliason-6G7rmG8pDCs-unsplash.jpg",
  },
];

interface PredefinedImagesProps {
  onImageSelect: (imageUrl: string) => void;
}

const PredefinedImages: React.FC<PredefinedImagesProps> = ({
  onImageSelect,
}) => {
  const handleImageClick = (fullImageUrl: string) => {
    const imageUrl = `${process.env.PUBLIC_URL}/images/${fullImageUrl}`;
    onImageSelect(imageUrl);
  };
  const { t } = useTranslation('demo');

  return (
    <div className="mt-4 sm:mt-8">
      <p className="text-center text-gray-700 dark:text-gray-300 mb-2 sm:mb-4 text-sm sm:text-base">
        {t('predefinedImages')}
      </p>
      <div className="flex flex-wrap justify-center gap-2 sm:gap-4">
        {predefinedImages.map((img, index) => (
          <img
            key={index}
            src={`${process.env.PUBLIC_URL}/images/${img.thumbnail}`}
            alt="Predefined Thumbnail"
            className="w-16 h-16 sm:w-20 sm:h-20 md:w-24 md:h-24 object-cover rounded-lg cursor-pointer hover:opacity-80 transition-opacity duration-200"
            onClick={() => handleImageClick(img.fullImage)}
          />
        ))}
      </div>
    </div>
  );
};

export default PredefinedImages;
