import React, { useEffect } from "react";
import { Code } from "lucide-react";
import { Link } from "react-router-dom";
import { useSecondaryNavbar } from "../../components/SecondaryNavbarContext";
import { useAuth } from "../../contexts/AuthContext/AuthContext";
import SEOHead from "../../components/SEOHead";
import { useTranslation } from "react-i18next";
import { useLocalizedNavigation } from "../../hooks/useLocalizedNavigation";
import {
  AuthContent,
  UnauthContent,
} from "../../components/SecondaryNavbars/ApiKeyAndCredits";

const DocumentationPage: React.FC = () => {
  const { setContent } = useSecondaryNavbar();
  const { isAuthenticated, credits, apiKey, expiryDate } = useAuth();
  const { t } = useTranslation("pages/docs/intro");
  const { createLocalizedLink } = useLocalizedNavigation();

  useEffect(() => {
    if (isAuthenticated) {
      setContent(
        <AuthContent
          credits={credits}
          apiKey={apiKey}
          expiryDate={expiryDate}
        />
      );
    } else {
      setContent(<UnauthContent />);
    }
    return () => {
      setContent(null);
    };
  }, [setContent, isAuthenticated, credits]);

  return (
    <>
      <SEOHead
        title={t("meta.title")}
        description={t("meta.description")}
        imageUrl="https://withoutbg.com/api-documentation-og.png"
      />
      <div className="w-full bg-light dark:bg-gray-900 text-gray-800 dark:text-gray-300 min-h-screen flex items-center justify-center pt-10">
        <div className="max-w-4xl mx-auto p-8">
          <div className="flex justify-center mb-8">
            <div className="bg-indigo-900 p-2 rounded-lg">
              <Code size={24} className="text-white" />
            </div>
          </div>

          <h1 className="text-4xl font-bold text-center mb-4 text-gray-900 dark:text-white">
            {t("header.title")}
          </h1>
          <p className="text-center text-gray-600 dark:text-gray-400 mb-12">
            {t("header.subtitle")}
          </p>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 relative">
            {/* PNG Image Option */}
            <div className="md:pr-8 flex flex-col justify-between">
              <div>
                <h2 className="text-2xl font-semibold mb-4 text-gray-900 dark:text-white flex flex-col items-center">
                  {t("transparentPng.title")}
                </h2>
                <div className="flex items-center mb-4 space-x-4">
                  <div className="w-[45%]">
                    <img
                      src="https://media.withoutbg.com/examples/dog.jpg"
                      alt="Original dog"
                      className="w-full h-auto"
                    />
                  </div>
                  <span className="text-2xl">→</span>
                  <div className="w-[45%] relative">
                    <div className="absolute inset-0 bg-checkerboard bg-checkerboard-size bg-checkerboard-repeat"></div>
                    <img
                      src="https://media.withoutbg.com/examples/dog.png"
                      alt="Dog with transparent background"
                      className="relative z-10 w-full h-auto"
                    />
                  </div>
                </div>
                <p className="text-sm text-gray-600 dark:text-gray-400 mb-4">
                  {t("transparentPng.description")}
                </p>
              </div>
              <div className="mt-auto flex flex-col items-center">
                <Link
                  to={createLocalizedLink(
                    "/documentation/transparent-background"
                  )}
                  className="inline-block bg-purple-800 hover:bg-purple-900 text-white font-bold py-2 px-4 rounded transition duration-300"
                >
                  {t("transparentPng.button")}
                </Link>
              </div>
            </div>

            {/* Alpha Matte Option */}
            <div className="md:border-l md:border-gray-300 dark:md:border-gray-700 md:pl-8 flex flex-col justify-between">
              <div>
                <h2 className="text-2xl font-semibold mb-4 text-gray-900 dark:text-white flex flex-col items-center">
                  {t("alphaMatte.title")}
                </h2>
                <div className="flex items-center mb-4 space-x-4">
                  <div className="w-[45%]">
                    <img
                      src="https://media.withoutbg.com/examples/dog.jpg"
                      alt="Original dog"
                      className="w-full h-auto"
                    />
                  </div>
                  <span className="text-2xl">→</span>
                  <div className="w-[45%]">
                    <img
                      src="https://media.withoutbg.com/examples/dog-alpha.png"
                      alt="Dog alpha matte"
                      className="w-full h-auto"
                    />
                  </div>
                </div>
                <p className="text-sm text-gray-600 dark:text-gray-400 mb-4">
                  {t("alphaMatte.description")}
                </p>
              </div>
              <div className="mt-auto flex flex-col items-center">
                <Link
                  to={createLocalizedLink("/documentation/alpha-channel")}
                  className="inline-block bg-purple-800 hover:bg-purple-900 text-white font-bold py-2 px-4 rounded transition duration-300"
                >
                  {t("alphaMatte.button")}
                </Link>
              </div>
            </div>
          </div>

          {/* Alternative Documentation Section */}
          <div className="mt-12">
            <h2 className="text-2xl font-semibold text-center mb-4 text-dark-400 dark:text-gray-200">
              {t("alternativeDocs.title")}
            </h2>
            <p className="text-center text-gray-400 mb-6">
              {t("alternativeDocs.subtitle")}
            </p>
            <div className="flex justify-center space-x-8">
              <div className="flex flex-col items-center">
                <a
                  href="https://api.withoutbg.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex flex-col items-center"
                >
                  <div className="bg-green-600 rounded-full p-2 mb-2 flex items-center justify-center">
                    <Code size={24} className="text-white" />
                  </div>
                  <span>{t("alternativeDocs.swaggerUI")}</span>
                </a>
              </div>
              <div className="flex flex-col items-center">
                <a
                  href="https://api.withoutbg.com/redoc"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex flex-col items-center"
                >
                  <div className="bg-gray-600 rounded-full p-2 mb-2 flex items-center justify-center">
                    <Code size={24} className="text-white" />
                  </div>
                  <span>{t("alternativeDocs.redoc")}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DocumentationPage;
