import React, { useState, useEffect } from "react";
import { Loader } from "lucide-react";
import { useTranslation } from "react-i18next";
import config from "../../config";
import { validateEmail } from "../../utils/validators";
import CloseButton from "./CloseButton";
import EmailInput from "./EmailInput";
import SuccessMessage from "./SuccessMessage";

interface LoginPopoverProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (email: string) => Promise<void>;
}

const LoginPopover: React.FC<LoginPopoverProps> = ({
  isOpen,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation("components/login");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);

  useEffect(() => {
    setIsValidEmail(validateEmail(email));
  }, [email]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setMessage("");
    setError("");
    setIsSubmitting(true);
    try {
      const response = await fetch(`${config.API_URL}/send-login-link`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ email }),
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.detail || t("popover.messages.error.fetchError")
        );
      }
      const data = await response.json();
      const successMessage = data.message || t("popover.messages.success");
      setMessage(successMessage);
      setIsSuccess(true);
      await onSubmit(email);
    } catch (error) {
      setError(
        error instanceof Error
          ? error.message
          : t("popover.messages.error.generic")
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 dark:bg-opacity-70 flex items-center justify-center z-50">
      <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg max-w-xl w-full relative z-50 max-h-[600px] overflow-y-auto">
        <CloseButton onClose={onClose} />
        {isSuccess ? (
          <SuccessMessage
            messageTitle={t("popover.messages.success.title")}
            messageDescription={t("popover.messages.success.description")}
          />
        ) : (
          <>
            <h2 className="text-3xl mb-6 text-gray-800 dark:text-gray-200">
              {t("popover.title")}
            </h2>
            <p className="text-sm text-gray-600 dark:text-gray-400 mb-5">
              {t("popover.description")}
            </p>
            <form onSubmit={handleSubmit} className="space-y-4">
              <EmailInput email={email} setEmail={setEmail} />
              <button
                type="submit"
                className="w-full bg-purple-600 text-white p-3 rounded-lg text-lg hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 disabled:opacity-50 disabled:cursor-not-allowed dark:bg-purple-500 dark:hover:bg-purple-600 dark:focus:ring-purple-400 transition-colors duration-200"
                disabled={!isValidEmail || isSubmitting}
              >
                {isSubmitting ? (
                  <>
                    <Loader className="animate-spin mx-auto" size={28} />
                    <span className="sr-only">
                      {t("popover.submitButton.loading")}
                    </span>
                  </>
                ) : (
                  t("popover.submitButton.default")
                )}
              </button>
            </form>
            {error && (
              <p className="text-red-600 dark:text-red-400 mt-4 text-center">
                {error}
              </p>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default LoginPopover;
