import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { loadStripe } from "@stripe/stripe-js";
import config from "../../config";
import { pricingData, PricingPlan } from "../pricingData";
import { useAuth } from "../../contexts/AuthContext/AuthContext";

const stripePromise = loadStripe(config.STRIPE_PUBLISHABLE_KEY);

type PaidPricingPlan = PricingPlan & {
  stripe_product_id: string;
  stripe_price_id: string;
};

const BuyCredit: React.FC = () => {
  const { t } = useTranslation("pricing");
  const { isAuthenticated } = useAuth();
  const [error, setError] = useState<string | null>(null);

  const paidPlans: PaidPricingPlan[] = pricingData.filter(
    (plan): plan is PaidPricingPlan =>
      plan.price > 0 &&
      typeof plan.stripe_product_id === "string" &&
      typeof plan.stripe_price_id === "string"
  );

  const handlePurchase = async (plan: PaidPricingPlan) => {
    if (!plan.stripe_price_id || !isAuthenticated) {
      setError(t("errors.invalidPlanOrAuth"));
      return;
    }

    const stripe = await stripePromise;
    const accessToken = localStorage.getItem("access_token");

    if (!accessToken) {
      setError(t("errors.noAccessToken"));
      return;
    }

    try {
      const response = await fetch(
        `${config.API_URL}/create-checkout-session`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            price_id: plan.stripe_price_id,
            product: plan.package,
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || t("errors.checkoutSessionFailed"));
      }

      const session = await response.json();

      const result = await stripe?.redirectToCheckout({
        sessionId: session.id,
      });

      if (result?.error) {
        throw new Error(result.error.message);
      }
    } catch (error) {
      console.error("Error during purchase:", error);
      setError(error instanceof Error ? error.message : t("errors.unknown"));
    }
  };

  return (
    <div className="bg-gray-100 dark:bg-gray-900 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 dark:text-gray-100 sm:text-4xl">
            {t("addCredits")}
          </h2>
          <p className="mt-4 text-xl text-gray-600 dark:text-gray-400">
            {t("choosePlan")}
          </p>
        </div>
        <div className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-5 sm:gap-6 lg:max-w-7xl lg:mx-auto">
          {paidPlans.map((plan) => (
            <div
              key={plan.package}
              className="bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden"
            >
              <div className="px-4 py-5">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100">
                  {t(`packages.${plan.package.toLowerCase()}`)}
                </h3>
                <div className="flex items-center mt-2">
                  <p className="text-2xl font-extrabold text-gray-900 dark:text-gray-100">
                    {t("currency", { price: plan.price })}
                  </p>
                  {plan.discount && (
                    <p className="ml-2 text-sm font-medium text-green-600 dark:text-green-400">
                      {t(`discounts.${plan.discount}`)}
                    </p>
                  )}
                </div>
                <p className="mt-1 text-sm text-gray-600 dark:text-gray-400">
                  {t("validity", { days: plan.validity_in_days })}
                </p>
                <ul className="mt-4 space-y-2">
                  <li className="text-sm text-gray-700 dark:text-gray-300">
                    {t("creditAmount", { amount: plan.credits })}
                  </li>
                  <li className="text-sm text-gray-700 dark:text-gray-300">
                    {t("pricePerCredit", {
                      price: plan.pricePerCredit.toFixed(2),
                    })}
                  </li>
                </ul>
              </div>
              <div className="px-4 py-5 bg-gray-50 dark:bg-gray-700">
                <button
                  onClick={() => handlePurchase(plan)}
                  className="w-full py-2 px-4 border border-transparent rounded-md shadow text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:focus:ring-offset-gray-800"
                >
                  {t("addCreditsButton", { amount: plan.credits })}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BuyCredit;
