import { useState, useCallback } from "react";
import config from "../../config";

export const useImageProcessor = (
  csrfToken: string | null,
  onImageUpload: (url: string) => void
) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [beforeImage, setBeforeImage] = useState<string | null>(null);
  const [processedImage, setProcessedImage] = useState<string | null>(null);
  const [imageWidth, setImageWidth] = useState<number | null>(null);
  const [imageHeight, setImageHeight] = useState<number | null>(null);

  const processImage = async (image: string) => {
    // If the image is not base64 encoded, fetch it and convert to base64
    if (!image.startsWith("data:image/")) {
      try {
        const response = await fetch(image);
        const blob = await response.blob();
        const reader = new FileReader();

        reader.onloadend = () => {
          const base64data = reader.result as string;
          processBase64Image(base64data); // Process image as base64
        };

        reader.readAsDataURL(blob);
      } catch (error) {
        console.error("Failed to fetch and process image from URL:", error);
      }
      return;
    }

    processBase64Image(image); // Process image directly if it's already base64
  };

  const processBase64Image = async (base64Image: string) => {
    setLoading(true);
    setBeforeImage(base64Image);

    try {
      // Send the base64 image to the backend to get the alpha channel
      const alphaChannelResponse = await fetch(`${config.API_URL}/alpha`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          image_base64: base64Image.replace(/^data:image\/[a-z]+;base64,/, ""),
        }),
      });

      if (alphaChannelResponse.ok) {
        const alphaChannelResult = await alphaChannelResponse.json();
        const alphaChannelImage = `data:image/png;base64,${alphaChannelResult.alpha_base64}`;

        // Apply the alpha channel to the original image
        const finalImage = await applyAlphaChannel(
          base64Image,
          alphaChannelImage
        );
        onImageUpload(finalImage); // Notify the parent component with the final image
        setProcessedImage(finalImage);
      } else {
        console.error(
          "Alpha channel processing failed:",
          alphaChannelResponse.statusText
        );
      }
    } catch (error) {
      console.error(
        "An error occurred during alpha channel processing:",
        error
      );
    } finally {
      setLoading(false);
    }
  };

  const applyAlphaChannel = async (
    originalImage: string,
    alphaChannelImage: string
  ): Promise<string> => {
    const originalImg = await loadImage(originalImage);
    const alphaImg = await loadImage(alphaChannelImage);

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    if (!ctx) {
      throw new Error("Could not get canvas context");
    }

    canvas.width = originalImg.width;
    canvas.height = originalImg.height;

    setImageWidth(canvas.width);
    setImageHeight(canvas.height);

    ctx.drawImage(originalImg, 0, 0);
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

    ctx.drawImage(alphaImg, 0, 0, canvas.width, canvas.height);
    const alphaData = ctx.getImageData(0, 0, canvas.width, canvas.height).data;

    for (let i = 0; i < imageData.data.length; i += 4) {
      imageData.data[i + 3] = alphaData[i]; // Replace the alpha channel
    }

    ctx.putImageData(imageData, 0, 0);

    return canvas.toDataURL("image/png");
  };

  const loadImage = (src: string): Promise<HTMLImageElement> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "Anonymous"; // Handle cross-origin images
      img.src = src;
      img.onload = () => resolve(img);
      img.onerror = reject;
    });
  };

  const resetImages = useCallback(() => {
    setBeforeImage(null);
    setProcessedImage(null);
    setImageWidth(null);
    setImageHeight(null);
  }, []);

  return {
    processImage,
    beforeImage,
    processedImage,
    loading,
    imageWidth,
    imageHeight,
    resetImages,
  };
};
