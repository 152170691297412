import { useTranslation } from "react-i18next";
import Coordinate from "../Captcha/Coordinate";

interface SubmitButtonProps {
  isValidEmail: boolean;
  termsAccepted: boolean;
  clickedCoordinates: Coordinate[];
  isSubmitting: boolean;
  isCaptchaReady: boolean;
}

const SubmitButton: React.FC<SubmitButtonProps> = ({
  isValidEmail,
  termsAccepted,
  clickedCoordinates,
  isSubmitting,
  isCaptchaReady,
}) => {
  const { t } = useTranslation("signup");

  const isDisabled =
    !isValidEmail ||
    !termsAccepted ||
    clickedCoordinates.length < 5 ||
    isSubmitting ||
    !isCaptchaReady;

  return (
    <button
      type="submit"
      className={`w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
        isDisabled
          ? "bg-purple-300 cursor-not-allowed"
          : "bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
      }`}
      disabled={isDisabled}
    >
      {isSubmitting ? t("button.submitting") : t("button.default")}
    </button>
  );
};

export default SubmitButton;
