import { useState } from "react";
import config from "../config";
import CaptchaChallenge from "../components/Captcha/Challenge";
import Coordinate from "../components/Captcha/Coordinate";

export const useContact = (
  initialEmail: string,
  initialMessage: string,
  captchaChallenge: CaptchaChallenge | null,
  clickedCoordinates: Coordinate[]
) => {
  const [email, setEmail] = useState(initialEmail);
  const [message, setMessage] = useState(initialMessage);
  const [responseMessage, setResponseMessage] = useState("");
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError("");
    setResponseMessage("");
    setIsSuccess(false);

    try {
      const captchaData = {
        uuid: captchaChallenge?.uuid,
        coordinates: clickedCoordinates.map((coord, index) => ({
          x: coord.x,
          y: coord.y,
        })),
      };

      const response = await fetch(`${config.API_URL}/contact`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, message, captcha: captchaData }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.detail || "Failed to send message");
      }

      setResponseMessage(data.message);
      setIsSuccess(true);
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError("An error occurred while sending your message.");
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    email,
    setEmail,
    message,
    setMessage,
    responseMessage,
    error,
    isSubmitting,
    isSuccess,
    handleSubmit,
  };
};
