import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLocalizedNavigation } from "../hooks/useLocalizedNavigation";

const Footer: React.FC = () => {
  const { t } = useTranslation("components/footer");
  const currentYear = new Date().getFullYear();
  const { createLocalizedLink } = useLocalizedNavigation();

  return (
    <footer className="bg-lighter dark:bg-darker text-gray-700 dark:text-gray-300 py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* Company Info */}
          <div className="col-span-1">
            <div className="flex items-center mb-4">
              <img
                src="https://media.withoutbg.com/logo.png"
                alt="withoutBG Logo"
                className="h-8 mr-2"
              />
              <span className="text-xl font-bold text-gray-900 dark:text-white">
                withoutBG API
              </span>
            </div>
            <p className="text-sm mb-2">{t("company.description")}</p>
            <p className="text-xs text-gray-500 dark:text-gray-400">
              {currentYear} © withoutBG API
            </p>
          </div>

          {/* Links Column */}
          <div>
            <h3 className="text-lg font-semibold mb-4 text-gray-900 dark:text-white">
              {t("sections.links.title")}
            </h3>
            <ul className="space-y-2">
              <li>
                <Link
                  to={createLocalizedLink("/privacy-policy")}
                  className="hover:text-gray-900 dark:hover:text-white transition"
                >
                  {t("sections.links.items.privacy")}
                </Link>
              </li>
              <li>
                <Link
                  to={createLocalizedLink("/terms-and-conditions")}
                  className="hover:text-gray-900 dark:hover:text-white transition"
                >
                  {t("sections.links.items.terms")}
                </Link>
              </li>
              <li>
                <Link
                  to={createLocalizedLink("/imprint")}
                  className="hover:text-gray-900 dark:hover:text-white transition"
                >
                  {t("sections.links.items.imprint")}
                </Link>
              </li>
            </ul>
          </div>

          {/* Guides Column */}
          <div>
            <h3 className="text-lg font-semibold mb-4 text-gray-900 dark:text-white">
              {t("sections.guides.title")}
            </h3>
            <ul className="space-y-2">
              <li>
                <Link
                  to={createLocalizedLink("/documentation")}
                  className="hover:text-gray-900 dark:hover:text-white transition"
                >
                  {t("sections.guides.items.documentation")}
                </Link>
              </li>
              <li>
                <Link
                  to={createLocalizedLink("/resources")}
                  className="hover:text-gray-900 dark:hover:text-white transition"
                >
                  {t("sections.guides.items.resources")}
                </Link>
              </li>
              <li>
                <Link
                  to={createLocalizedLink("/examples")}
                  className="hover:text-gray-900 dark:hover:text-white transition"
                >
                  {t("sections.guides.items.examples")}
                </Link>
              </li>
              <li>
                <Link
                  to={createLocalizedLink("/remove-background")}
                  className="hover:text-gray-900 dark:hover:text-white transition"
                >
                  {t("sections.guides.items.try")}
                </Link>
              </li>
            </ul>
          </div>

          {/* Empty column for balance */}
          <div></div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
