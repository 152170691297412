import React, { useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from 'react-i18next';
import ImageDisplay from "./ImageDisplay";

interface CanvasProps {
  imageUrl: string | null;
  isProcessed: boolean;
  onImageDrop: (file: File) => void;
  background: "checkerboard" | "color" | "image";
  backgroundColor?: string;
  backgroundImage: string | null;
  loading: boolean;
}

const Canvas: React.FC<CanvasProps> = ({
  imageUrl,
  isProcessed,
  onImageDrop,
  background,
  backgroundColor,
  backgroundImage,
  loading,
}) => {
  const { t } = useTranslation('demo');

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: {
        "image/*": [".jpeg", ".png", ".jpg", ".gif"],
      },
      onDrop: (acceptedFiles) => {
        if (acceptedFiles && acceptedFiles.length > 0) {
          onImageDrop(acceptedFiles[0]);
        }
      },
    });

  const dropzoneStyle = useMemo(
    () =>
      `w-full h-64 border-4 border-dashed rounded-lg flex flex-col items-center justify-center cursor-pointer transition-colors duration-300 ease-in-out
      bg-checkerboard bg-checkerboard-size bg-checkerboard-repeat relative
      ${isFocused ? "border-green-400" : "border-gray-300"}
      ${isDragAccept ? "border-green-400" : ""}
      ${isDragReject ? "border-red-400" : ""}`,
    [isFocused, isDragAccept, isDragReject]
  );

  const overlayStyle = useMemo(
    () =>
      `absolute inset-0 rounded-lg transition-colors duration-300 ease-in-out
      ${isFocused ? "bg-blue-50" : "bg-gray-50"}
      ${isDragAccept ? "bg-green-200" : ""}
      ${isDragReject ? "bg-red-50" : ""}
      opacity-50`,
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <div className="w-full max-w-4xl mx-auto">
      {!imageUrl ? (
        <div {...getRootProps({ className: dropzoneStyle })}>
          <div className={overlayStyle}></div>
          <input {...getInputProps()} />
          <button
            className="bg-purple-700 hover:bg-purple-600 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out transform hover:scale-105 mb-4 z-10 relative"
            onClick={(e) => {
              e.stopPropagation();
              (
                document.querySelector('input[type="file"]') as HTMLInputElement
              )?.click();
            }}
          >
            {t('dragAndDrop.button')}
          </button>
          <p className="text-gray-800 text-center font-semibold bg-white bg-opacity-70 px-4 py-2 rounded z-10 relative">
            {t('dragAndDrop.orDropHereText')}
          </p>
        </div>
      ) : (
        <div className="relative">
          <ImageDisplay
            imageUrl={imageUrl}
            isProcessed={isProcessed}
            background={background}
            backgroundColor={backgroundColor}
          />
        </div>
      )}
    </div>
  );
};

export default Canvas;