import { useState } from "react";
import config from "../config";
import CaptchaChallenge from "../components/Captcha/Challenge";
import Coordinate from "../components/Captcha/Coordinate";

interface UseSignUpResult {
  message: string;
  error: string;
  isSubmitting: boolean;
  isSuccess: boolean;
  handleSubmit: (e: React.FormEvent) => Promise<void>;
}

export const useSignUp = (
  email: string,
  captchaChallenge: CaptchaChallenge | null,
  clickedCoordinates: Coordinate[],
  onSubmit: (email: string) => Promise<void>
): UseSignUpResult => {
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setMessage("");
    setError("");
    setIsSubmitting(true);

    try {
      if (!captchaChallenge || clickedCoordinates.length < 5) {
        throw new Error("Please complete the CAPTCHA challenge");
      }

      const signUpResponse = await fetch(`${config.API_URL}/signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          email,
          captcha: {
            uuid: captchaChallenge.uuid,
            coordinates: clickedCoordinates,
          },
        }),
      });

      if (!signUpResponse.ok) {
        const errorData = await signUpResponse.json();
        throw new Error(errorData.detail || "Sign up failed");
      }

      const data = await signUpResponse.json();
      setMessage(data.message);
      setIsSuccess(true);
      await onSubmit(email);
    } catch (error) {
      setError(
        error instanceof Error
          ? error.message
          : "Sign up failed. Please try again."
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    message,
    error,
    isSubmitting,
    isSuccess,
    handleSubmit,
  };
};
