import React, { useEffect, useState, useRef, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import config from "../../config";
import { useAuth } from "../../contexts/AuthContext/AuthContext";

const CompleteSignUp: React.FC = () => {
  const [message, setMessage] = useState<string>("");
  const [error, setError] = useState<string>("");
  const location = useLocation();
  const navigate = useNavigate();
  const isCompletingRef = useRef(false);
  const { isAuthenticated, setToken } = useAuth();

  const completeRegistration = useCallback(async () => {
    if (isCompletingRef.current) return;
    isCompletingRef.current = true;

    const params = new URLSearchParams(location.search);
    const token = params.get("token");

    if (!token) {
      setError("Invalid registration link");
      isCompletingRef.current = false;
      return;
    }

    try {
      const response = await fetch(
        `${config.API_URL}/complete-signup?token=${token}`
      );
      if (!response.ok) {
        throw new Error("Registration completion failed");
      }
      const data = await response.json();
      setMessage(data.message);

      // Use the setToken function from AuthContext
      setToken(data.access_token);

      // Redirect to the home page or dashboard after a short delay
      setTimeout(() => navigate("/account"), 2000);
    } catch (error) {
      setError("Registration completion failed. Please try again.");
    } finally {
      isCompletingRef.current = false;
    }
  }, [location.search, navigate, setToken]);

  useEffect(() => {
    completeRegistration();
  }, [completeRegistration]);

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/account");
    }
  }, [isAuthenticated, navigate]);

  return (
    <div className="flex items-center justify-center min-h-screen bg-light dark:bg-dark">
      <div className="p-8 bg-light dark:bg-dark">
        {message && (
          <p className="text-purple-600 font-bold text-4xl">{message}</p>
        )}
        {error && <p className="text-red-600 text-xl">{error}</p>}
        {!message && !error && (
          <p className="text-gray-600 text-xl">Completing registration...</p>
        )}
      </div>
    </div>
  );
};

export default CompleteSignUp;
