import React from 'react';
import { useTranslation } from 'react-i18next';
import SEOHead from '../../components/SEOHead';

interface Credit {
    photographer: string;
    profileUrl: string;
    photoUrl: string;
}

interface ExampleItem {
    id: string;
    original: string;
    processed: string;
    credit: Credit;
}

interface Feature {
    id: string;
    title: string;
    description: string;
}

// Define the translation namespace type
type TranslationKey = string;
type TranslationDefaultValue = any;
type TranslationVars = { returnObjects: boolean };

const WithoutBG100: React.FC = () => {
    const { t } = useTranslation('resources/withoutbg100');

    // Helper function to safely get translated arrays
    const getTranslatedArray = <T,>(key: string): T[] => {
        return (t(key, { returnObjects: true }) as unknown as T[]) || [];
    };

    const features = getTranslatedArray<Feature>('withoutbg100.keyFeatures.features');
    const examples = getTranslatedArray<ExampleItem>('withoutbg100.examples.items');

    return (
        <>
            <SEOHead
                title={t('withoutbg100.introduction.title')}
                description={t('withoutbg100.introduction.description')}
                imageUrl="https://withoutbg.com/withoutbg100-dataset-og.png"
            />

            <div className="min-h-screen bg-white dark:bg-gray-900 mt-10">
                <main className="max-w-4xl mx-auto px-4 py-12">
                    {/* Introduction Section */}
                    <section className="mb-12">
                        <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-6">
                            {t('withoutbg100.introduction.title')}
                        </h1>
                        <p className="text-lg text-gray-700 dark:text-gray-300">
                            {t('withoutbg100.introduction.description')}
                        </p>
                    </section>

                    {/* Key Features Section */}
                    <section className="mb-12">
                        <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-6">
                            {t('withoutbg100.keyFeatures.title')}
                        </h2>
                        <div className="grid gap-6 md:grid-cols-3">
                            {features.map((feature) => (
                                <div
                                    key={feature.id}
                                    className="p-6 bg-gray-50 dark:bg-gray-800 rounded-lg shadow-md"
                                >
                                    <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-3">
                                        {feature.title}
                                    </h3>
                                    <p className="text-gray-700 dark:text-gray-300">
                                        {feature.description}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </section>

                    {/* Example Images Section */}
                    <section className="mb-12">
                        <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-6">
                            {t('withoutbg100.examples.title')}
                        </h2>
                        <div className="grid gap-8">
                            {examples.map((item) => (
                                <div key={item.id} className="space-y-4">
                                    <div className="grid md:grid-cols-2 gap-4">
                                        <div className="relative">
                                            <img
                                                src={item.original}
                                                alt={`Original ${item.id}`}
                                                className="w-full rounded-lg shadow-md"
                                                loading="lazy"
                                            />
                                            <span className="absolute top-2 left-2 bg-black/70 text-white px-2 py-1 rounded text-sm">
                                                {t('withoutbg100.labelOnImage.original')}
                                            </span>
                                        </div>
                                        <div className="relative">
                                            <img
                                                src={item.processed}
                                                alt={`Processed ${item.id}`}
                                                className="w-full rounded-lg shadow-md bg-grid"
                                                loading="lazy"
                                            />
                                            <span className="absolute top-2 left-2 bg-black/70 text-white px-2 py-1 rounded text-sm">
                                                {t('withoutbg100.labelOnImage.annotated')}
                                            </span>
                                        </div>
                                    </div>
                                    <p className="text-sm text-gray-600 dark:text-gray-400">
                                        Photo by{' '}
                                        <a
                                            href={item.credit.profileUrl}
                                            className="text-blue-600 dark:text-blue-400 hover:underline"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {item.credit.photographer}
                                        </a>{' '}
                                        on{' '}
                                        <a
                                            href={item.credit.photoUrl}
                                            className="text-blue-600 dark:text-blue-400 hover:underline"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Unsplash
                                        </a>
                                    </p>
                                </div>
                            ))}
                        </div>
                    </section>

                    {/* Attribution Section */}
                    <section className="mb-12">
                        <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
                            {t('withoutbg100.attribution.title')}
                        </h2>
                        <div className="prose dark:prose-invert max-w-none">
                            <p className="text-gray-700 dark:text-gray-300 mb-4">
                                {t('withoutbg100.attribution.description')}
                            </p>
                            <div className="p-4 bg-gray-50 dark:bg-gray-800 rounded-lg font-mono text-sm">
                                {t('withoutbg100.attribution.format')}
                            </div>
                        </div>
                    </section>

                    {/* Download Section */}
                    <section className="mb-12">
                        <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
                            {t('withoutbg100.download.title')}
                        </h2>
                        <p className="text-gray-700 dark:text-gray-300 mb-4">
                            {t('withoutbg100.download.description')}
                        </p>
                        <a
                            href={t('withoutbg100.download.url')}
                            className="inline-block bg-blue-600 hover:bg-blue-700 text-white font-semibold px-6 py-3 rounded-lg transition-colors duration-200"
                            download
                        >
                            {t('withoutbg100.download.button')}
                        </a>
                    </section>

                    {/* Acknowledgements Section */}
                    <section className="mb-12">
                        <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
                            {t('withoutbg100.acknowledgements.title')}
                        </h2>
                        <p className="text-gray-700 dark:text-gray-300 mb-4">
                            {t('withoutbg100.acknowledgements.description')}
                        </p>
                        <p className="text-sm text-gray-600 dark:text-gray-400 italic">
                            {t('withoutbg100.acknowledgements.disclaimer')}
                        </p>
                    </section>
                </main>

                {/* Back to Top Button */}
                <button
                    onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                    className="fixed bottom-8 right-8 bg-blue-500 hover:bg-blue-600 text-white p-3 rounded-full shadow-lg transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2 dark:focus:ring-offset-gray-900"
                    aria-label="Scroll to top"
                >
                    <svg
                        className="w-6 h-6"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path d="M5 10l7-7m0 0l7 7m-7-7v18" />
                    </svg>
                </button>
            </div>
        </>
    );
};

export default WithoutBG100;