import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import config from "../../config";

interface AuthContextProps {
  isAuthenticated: boolean;
  credits: number;
  apiKey: string;
  expiryDate: string;
  userEmail: string;
  logout: () => void;
  setToken: (token: string) => void;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [authData, setAuthData] = useState<
    Omit<AuthContextProps, "logout" | "setToken">
  >({
    isAuthenticated: false,
    credits: 0,
    apiKey: "",
    expiryDate: "",
    userEmail: "",
  });

  const fetchAuthData = async (token: string) => {
    try {
      const response = await fetch(`${config.API_URL}/account`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch account data");
      }

      const data = await response.json();
      setAuthData({
        isAuthenticated: true,
        credits: data.credits,
        apiKey: data.api_key,
        expiryDate: data.expiration_date,
        userEmail: data.user_email,
      });
    } catch (error) {
      console.error("Error fetching auth data:", error);
      setAuthData((prevState) => ({ ...prevState, isAuthenticated: false }));
      localStorage.removeItem("access_token");
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("access_token");
    if (token) {
      fetchAuthData(token);
    }
  }, []);

  const logout = () => {
    localStorage.removeItem("access_token");
    setAuthData({
      isAuthenticated: false,
      credits: 0,
      apiKey: "",
      expiryDate: "",
      userEmail: "",
    });
  };

  const setToken = (token: string) => {
    localStorage.setItem("access_token", token);
    fetchAuthData(token);
  };

  const contextValue: AuthContextProps = {
    ...authData,
    logout,
    setToken,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextProps => {
  const context = useContext(AuthContext);
  if (!context) throw new Error("useAuth must be used within an AuthProvider");
  return context;
};
