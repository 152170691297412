import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import BuyCredit from "../components/Account/BuyCredit";
import Summary from "../components/Account/Summary";
import DeleteAccount from "../components/Account/DeleteAccount";
import config from "../config";
import { useAuth } from "../contexts/AuthContext/AuthContext";
import TopUps from "../components/Account/PurchaseHistory";

interface UserData {
  message: string;
  user_email: string;
  api_key: string;
  expiration_date: string;
  credits: number;
  products: Record<string, number>;
}

interface AccountProps {
  onLoginClick: () => void;
}

const Account: React.FC<AccountProps> = ({ onLoginClick }) => {
  const [userData, setUserData] = useState<UserData | null>(null);
  const [error, setError] = useState<string | null>(null);
  const { isAuthenticated } = useAuth();
  const { t } = useTranslation("account");

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("access_token");
        if (!token) {
          setError(t("errors.noToken"));
          return;
        }

        const response = await fetch(`${config.API_URL}/account`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          localStorage.removeItem("access_token");
          throw new Error(t("errors.fetchFailed"));
        }

        const data: UserData = await response.json();
        setUserData(data);
      } catch (err) {
        setError(t("errors.generic"));
        console.error(t("errors.consoleError"), err);
      }
    };

    fetchUserData();
  }, [t]);

  return (
    <>
      <div className="App bg-gray-100 dark:bg-gray-900 min-h-screen py-12 px-4 sm:px-6 lg:px-8 pt-20">
        <div className="max-w-7xl mx-auto">
          {isAuthenticated ? (
            <>
              {userData && (
                <>
                  <Summary
                    apiKey={userData.api_key}
                    credits={userData.credits}
                    expiryDate={userData.expiration_date}
                  />
                  <BuyCredit />
                  <TopUps />
                </>
              )}
              {error && <p className="text-red-500">{error}</p>}

              {userData && (
                <hr className="my-8 mt-16 border-t border-gray-300 dark:border-gray-700" />
              )}

              {userData && (
                <div className="mt-8">
                  <DeleteAccount />
                </div>
              )}
            </>
          ) : (
            <div className="text-center text-gray-800 dark:text-gray-200">
              <p className="text-xl mb-4">{t("loginRequired")}</p>
              <button
                onClick={onLoginClick}
                className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded transition duration-300"
              >
                {t("loginButton")}
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Account;
