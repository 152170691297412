import React, { useState } from "react";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import {
  atomOneDark,
  stackoverflowLight,
} from "react-syntax-highlighter/dist/esm/styles/hljs";
import { useTheme } from "../../contexts/ThemeContext";

interface StatusCode {
  code: string;
  description: string;
  message: string;
}

interface StatusCodesProps {
  statusCodes: StatusCode[];
  defaultCode?: string;
}

const StatusCodes: React.FC<StatusCodesProps> = ({
  statusCodes,
  defaultCode = "500",
}) => {
  const [selectedCode, setSelectedCode] = useState(defaultCode);
  const { theme } = useTheme();

  const selectedStatus =
    statusCodes.find((status) => status.code === selectedCode) ||
    statusCodes[statusCodes.length - 1];

  return (
    <div className="bg-gray-100 dark:bg-gray-800 rounded-lg overflow-hidden shadow-md">
      <div className="px-4 py-3 bg-gray-200 dark:bg-gray-700 flex items-center">
        <h3 className="text-sm font-medium text-gray-700 dark:text-gray-300">
          RESPONSE
        </h3>
      </div>
      <div className="p-4">
        <div className="flex flex-wrap gap-2 mb-4">
          {statusCodes.map((status) => (
            <button
              key={status.code}
              onClick={() => setSelectedCode(status.code)}
              className={`px-2 py-1 text-xs font-medium rounded ${
                parseInt(status.code) < 300
                  ? "text-green-700 bg-green-100 dark:text-green-400 dark:bg-green-900"
                  : "text-red-700 bg-red-100 dark:text-red-400 dark:bg-red-900"
              } ${
                selectedCode === status.code
                  ? "ring-2 ring-offset-2 ring-blue-500 dark:ring-blue-400"
                  : ""
              }`}
            >
              {status.code}
            </button>
          ))}
        </div>
        <div className="rounded-md overflow-hidden">
          <SyntaxHighlighter
            language="js"
            style={theme === "dark" ? atomOneDark : stackoverflowLight}
            customStyle={{
              margin: 0,
              padding: "1rem",
              fontSize: "0.875rem",
              lineHeight: "1.25rem",
              backgroundColor: theme === "dark" ? "#1f2937" : "#f3f4f6",
            }}
          >
            {selectedStatus.message}
          </SyntaxHighlighter>
        </div>
      </div>
    </div>
  );
};

export default StatusCodes;
