import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import { useTheme } from "../../contexts/ThemeContext";
import {
  atomOneDark,
  stackoverflowLight,
} from "react-syntax-highlighter/dist/esm/styles/hljs";

interface CodeWindowProps {
  content: string;
  language?: string;
  title?: string;
  className?: string;
  showLineNumbers?: boolean;
  wrapLongLines?: boolean;
}

const CodeWindow: React.FC<CodeWindowProps> = ({
  content,
  language = "javascript",
  title = "CODE SAMPLE",
  className = "",
  showLineNumbers = false,
  wrapLongLines = false,
}) => {
  const { theme } = useTheme();

  return (
    <div
      className={`bg-gray-100 dark:bg-gray-800 rounded-lg overflow-hidden shadow-md ${className}`}
    >
      <div className="px-4 py-3 bg-gray-200 dark:bg-gray-700 flex items-center justify-between">
        <h3 className="text-sm font-medium text-gray-700 dark:text-gray-300">
          {title}
        </h3>
        <span className="text-xs text-gray-500 dark:text-gray-400">
          {language.toUpperCase()}
        </span>
      </div>
      <div className="p-4">
        <div className="rounded-md overflow-hidden">
          <SyntaxHighlighter
            language={language}
            style={theme === "dark" ? atomOneDark : stackoverflowLight}
            showLineNumbers={showLineNumbers}
            wrapLongLines={wrapLongLines}
            customStyle={{
              margin: 0,
              padding: "1rem",
              fontSize: "0.875rem",
              lineHeight: "1.25rem",
              background: "transparent",
            }}
          >
            {content}
          </SyntaxHighlighter>
        </div>
      </div>
    </div>
  );
};

export default CodeWindow;
