import React, { useState } from "react";
import BeforeAfterSlider from "./BeforeAfterSlider";
import Editor from "../Demo/Editor";
import { useTranslation } from "react-i18next";

interface TabContent {
  label: string;
  beforeImage?: string;
  afterImage?: string;
  aspectRatio?: number;
  alt?: string;
  type?: "gallery" | "upload";
}

const tabs: TabContent[] = [
  {
    label: "product",
    beforeImage: "/gallery/eniko-kis-KsLPTsYaqIQ-unsplash.jpg",
    afterImage: "/gallery/eniko-kis-KsLPTsYaqIQ-unsplash.png",
    aspectRatio: 640 / 427,
    alt: "Industrial camera",
    type: "gallery",
  },
  {
    label: "people",
    beforeImage: "/gallery/jamie-brown-wm4DuvIpLj8-unsplash.jpg",
    afterImage: "/gallery/jamie-brown-wm4DuvIpLj8-unsplash.png",
    aspectRatio: 640 / 427,
    alt: "Human image",
    type: "gallery",
  },
  {
    label: "animal",
    beforeImage: "/gallery/roger-chapman-M96JmCEMjfU-unsplash.jpg",
    afterImage: "/gallery/roger-chapman-M96JmCEMjfU-unsplash.png",
    aspectRatio: 640 / 427,
    alt: "Industrial camera",
    type: "gallery",
  },
  {
    label: "fineDetails",
    beforeImage: "/gallery/yuri-antonenko-BIyBZKx7SnM-unsplash.jpg",
    afterImage: "/gallery/yuri-antonenko-BIyBZKx7SnM-unsplash.png",
    aspectRatio: 5 / 3,
    alt: "Other image",
    type: "gallery",
  },
  {
    label: "upload",
    type: "upload",
  },
];

const TabbedGallery: React.FC = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleSignUpClick = () => {
    console.log("Sign Up Clicked");
  };
  const { t } = useTranslation('components/gallery');

  return (
    <div className="w-full max-w-5xl mx-auto p-4">
      {/* Centered Tabs */}
      <div className="flex justify-center border-b border-gray-300">
        <div className="inline-flex space-x-4">
          {tabs.map((tab, index) => (
            <button
              key={tab.label}
              onClick={() => setActiveTab(index)}
              className={`py-2 px-1 font-medium leading-5 transition-colors duration-200
                ${activeTab === index
                  ? "text-purple-600 border-b-4 border-purple-600 dark:text-purple-400 dark:border-purple-400"
                  : "text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-gray-200"
                }`}
            >
              {t(`headers.${tab.label}`)}

            </button>
          ))}
        </div>
      </div>

      {/* Tab Content */}
      <div className="mt-4">
        {tabs.map((tab, index) => (
          <div key={index} className={activeTab === index ? "block" : "hidden"}>
            {tab.type === "upload" ? (
              <div className="pt-10">
                <Editor showBottomActionBarAfterProcessing={false} />
              </div>
            ) : (
              <div className="max-w-2xl mx-auto">
                <BeforeAfterSlider
                  before={tab.beforeImage!}
                  after={tab.afterImage!}
                  alt={tab.alt!}
                  aspectRatio={tab.aspectRatio!}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TabbedGallery;
