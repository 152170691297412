import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import CodeWindow from "../../CodeWindow";
import SampleResponse from "../SimpleCodeWindow";
import StatusCodes from "../StatusCodes";
import Breadcrumb from "../Breadcrumb";

interface Script {
  name: string;
  language: string;
  content: string;
}

interface StatusCode {
  code: string;
  description: string;
  message: string;
}

const Credit: React.FC = () => {
  const [base64Scripts, setBase64Scripts] = useState<Script[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedLanguage, setSelectedLanguage] = useState<string>("cURL");
  const [sampleResponse, setSampleResponse] = useState<string>("");
  const { t } = useTranslation("pages/docs/credit");

  useEffect(() => {
    const generateSampleResponse = () => {
      const currentDate = new Date();
      const expirationDate = new Date(
        currentDate.getTime() + 90 * 24 * 60 * 60 * 1000
      );
      const formattedDate = expirationDate.toISOString().split("T")[0];

      const response = {
        credit: 100,
        expiration_date: formattedDate,
      };

      setSampleResponse(JSON.stringify(response, null, 2));
    };

    generateSampleResponse();
  }, []);

  const statusCodes: StatusCode[] = [
    {
      code: "200",
      description: "OK",
      message: "Success",
    },
    {
      code: "401",
      description: "Unauthorized",
      message: '{\n  "detail": "Invalid API Key"\n}',
    },
  ];

  useEffect(() => {
    const loadScripts = async () => {
      try {
        const creditScriptFiles = [
          {
            name: "cURL",
            language: "bash",
            path: "/scripts/credit/curl.sh",
          },
          {
            name: "Python",
            language: "python",
            path: "/scripts/credit/python.py",
          },
          {
            name: "Java",
            language: "java",
            path: "/scripts/credit/java.java",
          },
          {
            name: "PHP",
            language: "php",
            path: "/scripts/credit/php.php",
          },
          {
            name: "Node.js",
            language: "js",
            path: "/scripts/credit/node.js",
          },
          {
            name: "Go",
            language: "go",
            path: "/scripts/credit/go.go",
          },
          {
            name: "Ruby",
            language: "ruby",
            path: "/scripts/credit/ruby.rb",
          },
          {
            name: "JavaScript (Fetch)",
            language: "js",
            path: "/scripts/credit/javascript-fetch.js",
          },
          {
            name: "JavaScript (Axios)",
            language: "js",
            path: "/scripts/credit/javascript-axios.js",
          },
        ];

        const loadScriptsFromFiles = async (files: any[]) => {
          return await Promise.all(
            files.map(async (file) => {
              const response = await fetch(file.path);
              if (!response.ok) {
                throw new Error(t("errors.scriptLoad", { name: file.name }));
              }
              const content = await response.text();
              return { ...file, content };
            })
          );
        };

        const [loadedScriptFiles] = await Promise.all([
          loadScriptsFromFiles(creditScriptFiles),
        ]);

        setBase64Scripts(loadedScriptFiles);
        setIsLoading(false);
      } catch (err) {
        setError(err instanceof Error ? err.message : t("errors.generic"));
        setIsLoading(false);
      }
    };

    loadScripts();
  }, [t]);

  const handleLanguageChange = (language: string) => {
    setSelectedLanguage(language);
  };

  const getSelectedScript = (scripts: Script[], language: string) => {
    return scripts.find((script) => script.name === language) || scripts[0];
  };

  const breadcrumbItems = [
    { label: t("breadcrumb.home"), path: "/" },
    { label: t("breadcrumb.documentation"), path: "/documentation" },
    { label: t("breadcrumb.creditBalance"), path: "/documentation/credits" },
  ];

  return (
    <>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 w-full">
        <Breadcrumb items={breadcrumbItems} />
        <h1 className="text-4xl font-bold mb-4 text-gray-900 dark:text-gray-200">
          {t("title")}
        </h1>
        <p className="mb-6 text-gray-700 dark:text-gray-300">
          {t("description")}
        </p>

        <div className="w-full bg-gray-100 dark:bg-gray-800 rounded-lg overflow-hidden mb-8">
          {isLoading ? (
            <p className="p-4 text-gray-700 dark:text-gray-300">
              {t("loading")}
            </p>
          ) : error ? (
            <p className="p-4 text-red-500 dark:text-red-400">{error}</p>
          ) : (
            <CodeWindow
              scripts={base64Scripts}
              method="GET"
              target="/v1/available-credit"
              selectedScript={getSelectedScript(
                base64Scripts,
                selectedLanguage
              )}
              onScriptChange={handleLanguageChange}
            />
          )}
        </div>

        <p className="mb-6 text-gray-700 dark:text-gray-300">
          {t("responseDescription")}
        </p>
        <div className="pb-5">
          <li className="pt-1 pb-1 pr-5">
            <code className="px-2 py-1 rounded-md bg-gray-300 dark:bg-gray-800 text-code-text-light dark:text-code-text-dark">
              credit
            </code>
            : {t("creditDescription")}
          </li>
          <li className="pt-1 pb-1">
            <code className="px-2 py-1 rounded-md bg-gray-300 dark:bg-gray-800 text-code-text-light dark:text-code-text-dark">
              expiration_date
            </code>
            : {t("expirationDescription")}
          </li>
        </div>

        <SampleResponse content={sampleResponse} title={"SAMPLE RESPONSE"} />

        <h2 className="text-2xl font-bold mb-5 mt-10 text-gray-900 dark:text-gray-200">
          {t("responseCodes.title")}
        </h2>

        <div className="pb-20">
          <StatusCodes statusCodes={statusCodes} defaultCode="200" />
        </div>
      </div>
    </>
  );
};

export default Credit;
