// src/components/SecondaryNavbar.tsx
import React from "react";
import { useSecondaryNavbar } from "./SecondaryNavbarContext";
import { useAuth } from "../contexts/AuthContext/AuthContext";

const SecondaryNavbar: React.FC = () => {
  const { content } = useSecondaryNavbar();
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated || !content) return null;

  return (
    <div
      className="bg-gray-300 dark:bg-gray-800 text-center p-2 z-40 w-full h-12 flex items-center justify-between shadow-sm"
      style={{ position: "sticky", top: "48px" }}
    >
      <div className="flex-grow">{content}</div>
    </div>
  );
};

export default SecondaryNavbar;
