export interface PricingPlan {
  package: string;
  credits: number;
  price: number;
  pricePerCredit: number;
  validity_in_days: number;
  discount?: string;
  stripe_product_id?: string;
  stripe_price_id?: string;
}

export const pricingData: PricingPlan[] = [
  {
    package: "free",
    credits: 50,
    price: 0,
    pricePerCredit: 0,
    validity_in_days: 30,
  },
  //{
  //  package: "test",
  //  package_name_shown: "Test",
  //  credits: 1,
  //  price: 1,
  //  pricePerCredit: 1,
  //  validity_in_days: 1,
  //  stripe_product_id: "prod_MJGWu4KjkpwH4h",
  //  stripe_price_id: "price_1LadzPDZ1QYUF7H5PvJhUx6u",
  //},
  {
    package: "xs",
    credits: 100,
    price: 10,
    pricePerCredit: 0.1,
    validity_in_days: 30,
    stripe_product_id: "prod_NapVxIK6xvP6jM",
    stripe_price_id: "price_1MsYNaDZ1QYUF7H5s7LfXLvR",
  },
  {
    package: "s",
    credits: 500,
    price: 40,
    pricePerCredit: 0.08,
    validity_in_days: 30,
    discount: "20",
    stripe_product_id: "prod_NapXUp1vHorhRa",
    stripe_price_id: "price_1MsYMoDZ1QYUF7H54vX8oeTk",
  },
  {
    package: "m",
    credits: 1000,
    price: 70,
    pricePerCredit: 0.07,
    validity_in_days: 90,
    discount: "30",
    stripe_product_id: "prod_NapZbwPmiNcGws",
    stripe_price_id: "price_1MsYLBDZ1QYUF7H5nJ5XyxJY",
  },
  {
    package: "l",
    credits: 5000,
    price: 300,
    pricePerCredit: 0.06,
    validity_in_days: 180,
    discount: "40",
    stripe_product_id: "prod_Ndpnw2r6Y3dXKt",
    stripe_price_id: "price_1MsY7pDZ1QYUF7H54mVm8Ano",
  },
  {
    package: "xl",
    credits: 10000,
    price: 500,
    pricePerCredit: 0.05,
    validity_in_days: 360,
    discount: "50",
    stripe_product_id: "prod_NdporDNmItrM03",
    stripe_price_id: "price_1MsY96DZ1QYUF7H5mM2PgGhx",
  },
];
