import React from "react";
import ApiKeyDisplay from "../../ApiKeyDisplayBar";
import { useAuth } from "../../contexts/AuthContext/AuthContext";

// Define props for AuthContent if needed
interface AuthContentProps {
  // If you prefer passing props instead of using context
  apiKey: string;
  credits: number;
  expiryDate: string;
}

export const AuthContent: React.FC<AuthContentProps> = () => {
  const { isAuthenticated, credits, apiKey, expiryDate } = useAuth();

  if (!isAuthenticated) {
    return null;
  }

  return (
    <div className="items-center space-x-4">
      <div className="max-w-4xl mx-auto ">
        <div className="container mx-auto">
          <ApiKeyDisplay
            apiKey={apiKey}
            credits={credits}
            expirationDate={expiryDate}
          />
        </div>
      </div>
    </div>
  );
};

export const UnauthContent: React.FC = () => (
  <p className="dark:text-gray-100">
    🎁 50 free credits available after sign up.
  </p>
);
