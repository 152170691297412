import React from "react";
import SEOHead from "../../components/SEOHead";

const PrivacyPolicy: React.FC = () => {
  return (
    <>
      <SEOHead
        title={"Privacy Policy"}
        description={"Privacy Policy - withoutbg.com"}
        imageUrl="https://withoutbg.com/api-documentation-og.png"
      />
      <div className="w-full bg-light dark:bg-dark text-gray-800 dark:text-gray-300 pt-20">
        <div className="max-w-7xl mx-auto pt-8 pb-12 px-4 sm:px-6 lg:px-8">
          <div className="container font-mono">
            <h1 className="font-bold mt-4 m-b1">Privacy Policy</h1>
            <p>Last updated: February 26, 2021</p>
            <p>
              This Privacy Policy describes Our policies and procedures on the
              collection, use and disclosure of Your information when You use the
              Service and tells You about Your privacy rights and how the law
              protects You.
            </p>

            <h1 className="font-bold mt-4 m-b1">
              Interpretation and Definitions
            </h1>

            <h2 className="font-bold mt-4 m-b1">Interpretation</h2>
            <p>
              The words of which the initial letter is capitalized have meanings
              defined under the following conditions. The following definitions
              shall have the same meaning regardless of whether they appear in
              singular or in plural.
            </p>

            <h2 className="font-bold mt-4 m-b1">Definitions</h2>
            <p>For the purposes of this Privacy Policy:</p>
            <ul>
              <li>
                <p>
                  <strong>Website Operator</strong> refers to natural person,
                  Imran Kocabiyik, the builder and maintainer of the Website as a
                  personal project. (hereinafter, "us", "we", "I")
                </p>
              </li>

              <li>
                <p>
                  <strong>Website</strong> refers to withoutbg.com or the Service,
                  accessible from{" "}
                  <a
                    href="https://withoutbg.com/"
                    rel="external nofollow noopener"
                    target="_blank"
                  >
                    withoutbg.com
                  </a>
                </p>
              </li>

              <li>
                <p>
                  <strong>You</strong> means the individual accessing or using the
                  Service, or a company, or other legal entity on behalf of which
                  such individual is accessing or using the Service, as
                  applicable.
                </p>
              </li>
            </ul>
            <h1 className="font-bold mt-4 m-b1">Collecting and Using Data</h1>
            <h2 className="font-bold mt-4 m-b1">Types of Data Collected</h2>

            <h3>Images</h3>
            <p>
              The website stores the Images You upload for a limited period and it
              is deleted automatically. This duration is stated in the Image
              Upload forms. The website takes necessary measures to store them
              securely and delete them periodically, but it does not guarantee
              that those operations are executed successfully.
            </p>
            <ul></ul>
            <h3>Usage Data</h3>
            <p>Usage Data is collected automatically when using the Service.</p>
            <p>
              Usage Data may include information such as Your Device's Internet
              Protocol address (e.g. IP address), browser type, browser version,
              the pages of our Service that You visit, the time and date of Your
              visit, the time spent on those pages, unique device identifiers and
              other diagnostic data.
            </p>
            <p>
              When You access the Service by or through a mobile device, We may
              collect certain information automatically, including, but not
              limited to, the type of mobile device You use, Your mobile device
              unique ID, the IP address of Your mobile device, Your mobile
              operating system, the type of mobile Internet browser You use,
              unique device identifiers and other diagnostic data.
            </p>
            <p>
              We may also collect information that Your browser sends whenever You
              visit our Service or when You access the Service by or through a
              mobile device.
            </p>
            <h3>Tracking Technologies and Cookies</h3>
            <p>
              We use Cookies and similar tracking technologies to track the
              activity on Our Service and store certain information. Tracking
              technologies used are beacons, tags, and scripts to collect and
              track information and to improve and analyze Our Service. The
              technologies We use may include:
            </p>
            <ul>
              <li>
                <strong>Cookies or Browser Cookies.</strong> A cookie is a small
                file placed on Your Device. You can instruct Your browser to
                refuse all Cookies or to indicate when a Cookie is being sent.
                However, if You do not accept Cookies, You may not be able to use
                some parts of our Service. Unless you have adjusted Your browser
                setting so that it will refuse Cookies, our Service may use
                Cookies.
              </li>
              <li>
                <strong>Flash Cookies.</strong> Certain features of our Service
                may use local stored objects (or Flash Cookies) to collect and
                store information about Your preferences or Your activity on our
                Service. Flash Cookies are not managed by the same browser
                settings as those used for Browser Cookies. For more information
                on how You can delete Flash Cookies, please read "Where can I
                change the settings for disabling, or deleting local shared
                objects?" available at{" "}
                <a
                  href="https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_"
                  rel="external nofollow noopener"
                  target="_blank"
                >
                  https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_
                </a>
              </li>
            </ul>
            <p>
              Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies
              remain on Your personal computer or mobile device when You go
              offline, while Session Cookies are deleted as soon as You close Your
              web browser. You can learn more about cookies here:{" "}
              <a href="https://www.termsfeed.com/blog/cookies/" target="_blank">
                All About Cookies by TermsFeed
              </a>
              .
            </p>
            <p>
              We use both Session and Persistent Cookies for the purposes set out
              below:
            </p>
            <ul>
              <li>
                <p>
                  <strong>Necessary / Essential Cookies</strong>
                </p>
                <p>Type: Session Cookies</p>
                <p>Administered by: Us</p>
                <p>
                  Purpose: These Cookies are essential to provide You with
                  services available through the Website and to enable You to use
                  some of its features. They help to authenticate users and
                  prevent fraudulent use of user accounts. Without these Cookies,
                  the services that You have asked for cannot be provided, and We
                  only use these Cookies to provide You with those services.
                </p>
              </li>
              <li>
                <p>
                  <strong>Cookies Policy / Notice Acceptance Cookies</strong>
                </p>
                <p>Type: Persistent Cookies</p>
                <p>Administered by: Us</p>
                <p>
                  Purpose: These Cookies identify if users have accepted the use
                  of cookies on the Website.
                </p>
              </li>
              <li>
                <p>
                  <strong>Functionality Cookies</strong>
                </p>
                <p>Type: Persistent Cookies</p>
                <p>Administered by: Us</p>
                <p>
                  Purpose: These Cookies allow us to remember choices You make
                  when You use the Website, such as remembering your login details
                  or language preference. The purpose of these Cookies is to
                  provide You with a more personal experience and to avoid You
                  having to re-enter your preferences every time You use the
                  Website.
                </p>
              </li>
            </ul>
            <p>
              For more information about the cookies we use and your choices
              regarding cookies, please visit our Cookies Policy or the Cookies
              section of our Privacy Policy.
            </p>
            <h3>Law enforcement</h3>
            <p>
              Under certain circumstances, the Website Operator may be required to
              disclose Your Personal Data if required to do so by law or in
              response to valid requests by public authorities (e.g. a court or a
              government agency).
            </p>
            <h3>Other legal requirements</h3>
            <p>
              The Website Operator may disclose Your Personal Data in the good
              faith belief that such action is necessary to:
            </p>
            <ul>
              <li>Comply with a legal obligation</li>
              <li>
                Protect and defend the rights or property of the Website Operator
              </li>
              <li>
                Prevent or investigate possible wrongdoing in connection with the
                Service
              </li>
              <li>
                Protect the personal safety of Users of the Service or the public
              </li>
              <li>Protect against legal liability</li>
            </ul>

            <h1 className="font-bold mt-4 m-b1">Security</h1>
            <p>
              The Website takes necessary measures to protect the security of Your
              data. However, it’s impossible for Us to completely guarantee that
              user data will be immune from malicious attack or compromise.
              Therefore, You should note that your transmission of personal data
              is always at Your own risk.
            </p>

            <h1 className="font-bold mt-4 m-b1">Children's Privacy</h1>
            <p>
              Our Service does not address anyone under the age of 13. We do not
              knowingly collect personally identifiable information from anyone
              under the age of 13. If You are a parent or guardian and You are
              aware that Your child has provided Us with Personal Data, please
              contact Us. If We become aware that We have collected Personal Data
              from anyone under the age of 13 without verification of parental
              consent, We take steps to remove that information from Our servers.
            </p>
            <p>
              If We need to rely on consent as a legal basis for processing Your
              information and Your country requires consent from a parent, We may
              require Your parent's consent before We collect and use that
              information.
            </p>
            <h1 className="font-bold mt-4 m-b1">Links to Other Websites</h1>
            <p>
              Our Service may contain links to other websites that are not
              operated by Us. If You click on a third party link, You will be
              directed to that third party's site. We strongly advise You to
              review the Privacy Policy of every site You visit.
            </p>
            <p>
              We have no control over and assume no responsibility for the
              content, privacy policies or practices of any third party sites or
              services.
            </p>
            <h1 className="font-bold mt-4 m-b1">
              Changes to this Privacy Policy
            </h1>
            <p>
              We may update Our Privacy Policy from time to time. We will notify
              You of any changes by posting the new Privacy Policy on this page.
            </p>
            <p>
              We will let You know via email and/or a prominent notice on Our
              Service, prior to the change becoming effective and update the "Last
              updated" date at the top of this Privacy Policy.
            </p>
            <p>
              You are advised to review this Privacy Policy periodically for any
              changes. Changes to this Privacy Policy are effective when they are
              posted on this page.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
