import React, { useState, useEffect } from "react";

interface ImageDisplayProps {
  imageUrl: string;
  isProcessed: boolean;
  background: "checkerboard" | "color" | "image";
  backgroundColor?: string;
  backgroundImage?: string | null;
}

const ImageDisplay: React.FC<ImageDisplayProps> = ({
  imageUrl,
  isProcessed,
  background,
  backgroundColor = "#FFFFFF",
  backgroundImage,
}) => {
  const [imageDimensions, setImageDimensions] = useState<{
    width: number;
    height: number;
  } | null>(null);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setImageDimensions({ width: img.width, height: img.height });
    };
    img.src = imageUrl;
  }, [imageUrl]);

  return (
    <div className="w-full max-w-4xl mx-auto relative overflow-hidden flex justify-center items-center">
      <div
        style={{
          width: "100%",
          height: "100%",
          maxWidth: imageDimensions ? `${imageDimensions.width}px` : "100%",
          maxHeight: "80vh",
          position: "relative",
          aspectRatio: imageDimensions
            ? `${imageDimensions.width} / ${imageDimensions.height}`
            : "auto",
        }}
      >
        {isProcessed && (
          <div
            className={`absolute inset-0 ${
              background === "checkerboard"
                ? "bg-checkerboard bg-checkerboard-size bg-checkerboard-repeat"
                : ""
            }`}
            style={
              background === "color"
                ? { backgroundColor }
                : background === "image" && backgroundImage
                ? {
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }
                : {}
            }
          />
        )}
        <img
          src={imageUrl}
          alt="Uploaded image"
          className="w-full h-full object-contain relative z-10"
        />
      </div>
    </div>
  );
};

export default ImageDisplay;
