import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Trash2 } from "lucide-react";
import Alert, { AlertTitle, AlertDescription } from "./Alert";
import config from "../../config";
import { useAuth } from "../../contexts/AuthContext/AuthContext";

const DeleteAccount: React.FC = () => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmText, setConfirmText] = useState("");
  const { logout } = useAuth();
  const { t } = useTranslation("account");

  const handleDelete = async () => {
    if (confirmText === t("deleteAccount.confirmText")) {
      try {
        const response = await fetch(`${config.API_URL}/account-delete`, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });

        if (response.ok) {
          logout();
        } else {
          throw new Error(t("errors.deleteFailed"));
        }
      } catch (error) {
        console.error(t("errors.consoleError"), error);
        alert(t("errors.alertMessage"));
      }
    }
  };

  return (
    <div className="flex justify-center w-full">
      <div className="max-w-7xl w-full mt-8 p-4 overflow-hidden">
        <h2 className="text-xl font-bold text-gray-600 dark:text-gray-400 mb-2 text-center">
          {t("deleteAccount.dangerZone")}
        </h2>
        {!showConfirmation ? (
          <div className="text-center">
            <button
              onClick={() => setShowConfirmation(true)}
              className="text-sm text-red-500 hover:text-red-700 transition-colors duration-200"
            >
              {t("deleteAccount.deleteButton")}
            </button>
          </div>
        ) : (
          <div className="mt-4">
            <Alert variant="destructive">
              <div className="flex items-center">
                <Trash2 className="h-4 w-4 mr-2" />
                <AlertTitle>{t("deleteAccount.deleteTitle")}</AlertTitle>
              </div>
              <AlertDescription>
                {t("deleteAccount.deleteDescription")}
              </AlertDescription>
            </Alert>
            <input
              type="text"
              value={confirmText}
              onChange={(e) => setConfirmText(e.target.value)}
              className="mt-2 p-2 border border-gray-300 dark:border-gray-600 rounded w-full 
                bg-white dark:bg-gray-800 
                text-gray-900 dark:text-gray-100 
                placeholder-gray-500 dark:placeholder-gray-400
                transition-colors duration-200"
              placeholder={t("deleteAccount.inputPlaceholder")}
            />
            <button
              onClick={handleDelete}
              disabled={confirmText !== t("deleteAccount.confirmText")}
              className="mt-2 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition-colors duration-200 disabled:bg-red-300 disabled:cursor-not-allowed"
            >
              {t("deleteAccount.deleteButton")}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default DeleteAccount;
