import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Languages, Check } from "lucide-react";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const languages = [
    { code: "en", name: "English" },
    { code: "de", name: "Deutsch" },
    { code: "es", name: "Español" },
    { code: "fr", name: "Français" },
  ];

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleLanguageChange = (language: string) => {
    i18n.changeLanguage(language);
    setIsOpen(false);
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <div
        className="flex items-center space-x-2 cursor-pointer rounded-lg p-2 transition-colors duration-200 hover:bg-purple-600"
        onClick={() => setIsOpen(!isOpen)}
      >
        <Languages className="w-5 h-5" />
        <span className="text-sm uppercase">{i18n.language}</span>
      </div>

      {isOpen && (
        <div className="absolute right-0 mt-2 py-2 w-40 bg-white dark:bg-gray-800 rounded-md shadow-lg ring-1 ring-black ring-opacity-5">
          {languages.map((lang) => (
            <div
              key={lang.code}
              className="flex items-center px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
              onClick={() => handleLanguageChange(lang.code)}
            >
              <span className="w-4 mr-3">
                {i18n.language === lang.code && (
                  <Check className="w-4 h-4 text-green-500" />
                )}
              </span>
              <span className="uppercase">{lang.code}</span> - {lang.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguageSwitcher;
