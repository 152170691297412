import { useState, useCallback } from "react";

export interface AuthState {
  isLoginOpen: boolean;
  isSignUpOpen: boolean;
  authSuccess: boolean;
  openLogin: () => void;
  closeLogin: () => void;
  handleSignUp: (email: string) => Promise<void>;
  handleAuthAction: (email: string, isSignUp: boolean) => Promise<void>;
}

export const useAuthState = (): AuthState => {
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isSignUpOpen, setIsSignUpOpen] = useState(false);
  const [authSuccess, setAuthSuccess] = useState(false);

  const openLogin = useCallback(() => {
    setIsLoginOpen(true);
  }, []);

  const closeLogin = useCallback(() => {
    setIsLoginOpen(false);
  }, []);

  const handleSignUp = useCallback(async (email: string) => {
    setAuthSuccess(true);
    setTimeout(() => {
      setAuthSuccess(false);
    }, 7000);
  }, []);

  const handleAuthAction = useCallback(
    async (email: string, isSignUp: boolean) => {
      try {
        console.log(
          `${isSignUp ? "Signed up" : "Login requested"} with email:`,
          email
        );
        setAuthSuccess(true);
        setTimeout(() => {
          if (isSignUp) {
            setIsSignUpOpen(false);
          } else {
            setIsLoginOpen(false);
          }
          setAuthSuccess(false);
        }, 7000);
      } catch (error) {
        console.error(`Error during ${isSignUp ? "sign up" : "login"}:`, error);
      }
    },
    []
  );

  return {
    isLoginOpen,
    isSignUpOpen,
    authSuccess,
    openLogin,
    closeLogin,
    handleSignUp,
    handleAuthAction,
  };
};
