export const SUPPORTED_LANGUAGES = ["en", "de", "es", "fr"] as const;
export const DEFAULT_LANGUAGE = "en";

export type SupportedLanguage = (typeof SUPPORTED_LANGUAGES)[number];

export const isValidLanguage = (lang: string): lang is SupportedLanguage => {
  return SUPPORTED_LANGUAGES.includes(lang as SupportedLanguage);
};

export const stripLanguageFromPath = (path: string): string => {
  const segments = path.split("/").filter(Boolean);
  if (segments.length === 0) return "/";

  const firstSegment = segments[0];
  if (isValidLanguage(firstSegment)) {
    return "/" + segments.slice(1).join("/");
  }
  return path;
};

export const addLanguageToPath = (
  path: string,
  language: SupportedLanguage
): string => {
  // Don't add language prefix for default language
  if (language === DEFAULT_LANGUAGE) {
    return stripLanguageFromPath(path);
  }

  const strippedPath = stripLanguageFromPath(path);
  return `/${language}${strippedPath === "/" ? "" : strippedPath}`;
};

// Helper to get browser language that matches our supported languages
export const getBrowserLanguage = (): SupportedLanguage => {
  const browserLang = navigator.language.split("-")[0];
  return isValidLanguage(browserLang) ? browserLang : DEFAULT_LANGUAGE;
};

// Optional: Helper to get language from URL path
export const getLanguageFromPath = (path: string): SupportedLanguage => {
  const segments = path.split("/").filter(Boolean);
  const firstSegment = segments[0];
  return isValidLanguage(firstSegment) ? firstSegment : DEFAULT_LANGUAGE;
};
