import React, { useState } from "react";
import { Eye, EyeOff, Copy, Check } from "lucide-react";

const APIKeyDisplay = ({ apiKey = "" }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const maskedKey = apiKey.substring(0, 6) + "•".repeat(apiKey.length - 6);

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(apiKey);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy:", err);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <h2 className="mb-4 text-2xl font-semibold text-center text-gray-900 dark:text-white">
        API Key
      </h2>
      <div className="flex items-center space-x-2 bg-gray-300 dark:bg-gray-700">
        <code className="flex-1 bg-gray-200 p-2 font-mono text-sm text-gray-900 dark:bg-gray-800 dark:text-gray-100 text-center">
          {isVisible ? apiKey : maskedKey}
        </code>

        <button
          onClick={() => setIsVisible(!isVisible)}
          className="rounded-full p-2 text-gray-600 transition-colors duration-200 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700"
          aria-label={isVisible ? "Hide API key" : "Show API key"}
        >
          {isVisible ? (
            <EyeOff className="h-4 w-4" />
          ) : (
            <Eye className="h-4 w-4" />
          )}
        </button>

        <button
          onClick={copyToClipboard}
          className="rounded-full p-2 text-gray-600 transition-colors duration-200 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700"
          aria-label="Copy API key to clipboard"
        >
          {isCopied ? (
            <Check className="h-4 w-4 text-green-500 dark:text-green-400" />
          ) : (
            <Copy className="h-4 w-4" />
          )}
        </button>
      </div>
    </div>
  );
};

export default APIKeyDisplay;