import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import './config/i18n';
import Home from "./pages/Home";
import './globals.css';
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
console.log(`
  Welcome to the source code!
  You can get 50 free credits when you sign up.
  🎁 Additionally, you can get 20% off with this code: "FoundThisCodeInSource".
`);
root.render(
  <React.StrictMode>
    <Home />
  </React.StrictMode>
);

reportWebVitals();
