// config/i18n.ts
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { SUPPORTED_LANGUAGES, DEFAULT_LANGUAGE } from "../utils/languageUtils";

const config = {
  fallbackLng: DEFAULT_LANGUAGE,
  supportedLngs: SUPPORTED_LANGUAGES,

  detection: {
    order: ["path", "navigator"],
    lookupFromPathIndex: 0,
  },

  backend: {
    loadPath: "/locales/{{lng}}/{{ns}}.json",
  },

  defaultNS: "common",
  ns: ["common", "hero", "documentation", "resources/index"],

  react: {
    useSuspense: true, // Enable Suspense
    bindI18n: "languageChanged",
    bindI18nStore: "added removed",
    transEmptyNodeValue: "",
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ["br", "strong", "i"],
    skipTranslationOnMissingKey: false,
  },

  interpolation: {
    escapeValue: false,
  },
};

i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init(config);

export default i18n;
