import React, { useState } from "react";
import { useTranslation } from 'react-i18next';

interface FAQItem {
  question: string;
  answer: string;
}

const FAQ: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const { t } = useTranslation('components/faq');

  const toggleQuestion = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="bg-lighter dark:bg-darker text-gray-800 dark:text-gray-300 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-center mb-8 text-gray-900 dark:text-white">
          {t('title')}
        </h2>
        <div className="space-y-4">
          {Array.from({ length: 8 }, (_, index) => (
            <div
              key={index}
              className="border-b border-gray-200 dark:border-gray-700 pb-4"
            >
              <button
                className="flex justify-between items-center w-full text-left"
                onClick={() => toggleQuestion(index)}
              >
                <span className="text-lg font-medium text-gray-900 dark:text-white">
                  {t(`items.${index}.question`)}
                </span>
                <svg
                  className={`w-5 h-5 text-gray-500 transform ${activeIndex === index ? "rotate-180" : ""
                    } transition-transform duration-200`}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>
              {activeIndex === index && (
                <div className="mt-2 text-gray-600 dark:text-gray-300">
                  {t(`items.${index}.answer`)}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;