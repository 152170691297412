import React, { useEffect } from "react";

const NotFound: React.FC = () => {
  useEffect(() => {
    // Set status code to 404
    document.title = "404 - Page Not Found | WithoutBG";
    const meta = document.createElement("meta");
    meta.setAttribute("name", "prerender-status-code");
    meta.setAttribute("content", "404");
    document.head.appendChild(meta);

    return () => {
      document.head.removeChild(meta);
    };
  }, []);

  return (
    <div className="flex flex-col items-center justify-center min-h-[50vh] p-4">
      <h1 className="text-4xl font-bold mb-4">404 - Page Not Found</h1>
      <p className="text-lg mb-4">The page you're looking for doesn't exist.</p>
      <a href="/" className="text-blue-600 hover:text-blue-800">
        Go back to home
      </a>
    </div>
  );
};

export default NotFound;
