import React from "react";
import { pricingData } from "./pricingData";
import { useTranslation } from "react-i18next";

const Pricing: React.FC = () => {
  const { t } = useTranslation("pricing");

  return (
    <div className="w-full bg-lighter dark:bg-darker text-gray-800 dark:text-gray-300 pt-20">
      <div className="container mx-auto pt-8 pb-12 px-4 max-w-4xl">
        <h1 className="text-3xl md:text-4xl font-bold mb-3 text-center text-gray-900 dark:text-white">
          {t("title")}
        </h1>
        <p className="text-sm md:text-base text-center mb-8 text-gray-600 dark:text-gray-400">
          {t("subtitle")}
        </p>
        <div className="overflow-x-auto">
          <table className="w-full max-w-3xl mx-auto border-collapse text-xs md:text-base">
            <thead>
              <tr className="bg-gray-100 dark:bg-gray-800">
                <th className="text-left py-2 md:py-3 px-2 md:px-4 font-semibold border-b border-gray-200 dark:border-gray-700">
                  {t("table.headers.package")}
                </th>
                <th className="text-left py-2 md:py-3 px-2 md:px-4 font-semibold border-b border-gray-200 dark:border-gray-700">
                  {t("table.headers.credits")}
                </th>
                <th className="text-left py-2 md:py-3 px-2 md:px-4 font-semibold border-b border-gray-200 dark:border-gray-700">
                  {t("table.headers.price")}
                </th>
                <th className="text-left py-2 md:py-3 px-2 md:px-4 font-semibold border-b border-gray-200 dark:border-gray-700">
                  {t("table.headers.pricePerCredit")}
                </th>
                <th className="text-left py-2 md:py-3 px-2 md:px-4 font-semibold border-b border-gray-200 dark:border-gray-700">
                  {t("table.headers.validity")}
                </th>
              </tr>
            </thead>
            <tbody>
              {pricingData.map((plan, index) => (
                <tr
                  key={index}
                  className="border-b border-gray-200 dark:border-gray-800"
                >
                  <td className="py-2 md:py-3 px-2 md:px-4">
                    {t(`packages.${plan.package}`)}
                  </td>
                  <td className="py-2 md:py-3 px-2 md:px-4">
                    {plan.credits.toLocaleString()}
                  </td>
                  <td className="py-2 md:py-3 px-2 md:px-4">
                    {t("currency", { price: plan.price })}
                  </td>
                  <td className="py-2 md:py-3 px-2 md:px-4">
                    {t("currency", { price: plan.pricePerCredit.toFixed(2) })}
                    {plan.discount && (
                      <span className="ml-2 text-green-600 dark:text-green-400 text-xs md:text-sm">
                        {t(`discounts.${plan.discount}`)}
                      </span>
                    )}
                  </td>
                  <td className="py-2 md:py-3 px-2 md:px-4">
                    {t("validity", { days: plan.validity_in_days })}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
