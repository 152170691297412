import React from "react";
import { useTranslation } from "react-i18next";
import config from "../../config";

interface TopUp {
  date: string;
  package?: string;
  credits: number;
  price: number;
  invoice_link: string | null;
}

const PurchaseHistory: React.FC = () => {
  const [topUps, setTopUps] = React.useState<TopUp[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<string | null>(null);
  const { t } = useTranslation("pricing");

  React.useEffect(() => {
    const fetchTopUps = async () => {
      try {
        const token = localStorage.getItem("access_token");
        if (!token) {
          throw new Error(t("errors.noAccessToken"));
        }

        const response = await fetch(
          `${config.API_URL}/account/purchase-history`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(t("errors.fetchFailed"));
        }

        const data = await response.json();
        setTopUps(data);
      } catch (error) {
        console.error(t("errors.fetchError"), error);
        setError(t("errors.loadFailed"));
      } finally {
        setLoading(false);
      }
    };

    fetchTopUps();
  }, [t]);

  const getPackageName = (packageCode?: string) => {
    return packageCode
      ? t(`packages.${packageCode.toLowerCase()}`)
      : t("labels.notApplicable");
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[200px]">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900 dark:border-gray-100" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 text-center">
        <div className="text-red-500 dark:text-red-400">{error}</div>
      </div>
    );
  }

  if (topUps.length === 0) {
    return (
      <div className="p-4 text-center text-gray-600 dark:text-gray-400">
        {t("purchaseHistory.empty")}
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl text-center font-bold mb-6 text-gray-800 dark:text-gray-200 pt-10">
        {t("purchaseHistory.title")}
      </h2>
      <div className="overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                {t("table.headers.date")}
              </th>
              <th scope="col" className="px-6 py-3">
                {t("table.headers.package")}
              </th>
              <th scope="col" className="px-6 py-3">
                {t("table.headers.credits")}
              </th>
              <th scope="col" className="px-6 py-3">
                {t("table.headers.price")}
              </th>
              <th scope="col" className="px-6 py-3">
                {t("table.headers.invoice")}
              </th>
            </tr>
          </thead>
          <tbody>
            {topUps.map((topUp, index) => (
              <tr
                key={`${topUp.date}-${index}`}
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              >
                <td className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                  {new Date(topUp.date).toLocaleDateString()}
                </td>
                <td className="px-6 py-4">{getPackageName(topUp.package)}</td>
                <td className="px-6 py-4">
                  {t("creditAmount", { amount: topUp.credits })}
                </td>
                <td className="px-6 py-4">
                  {t("currency", { price: topUp.price.toFixed(2) })}
                </td>
                <td className="px-6 py-4">
                  {topUp.invoice_link ? (
                    <a
                      href={topUp.invoice_link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                    >
                      {t("labels.downloadInvoice")}
                    </a>
                  ) : (
                    <span className="text-gray-500">
                      {t("labels.notApplicable")}
                    </span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PurchaseHistory;
