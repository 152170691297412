import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import CodeWindow from "../../CodeWindow";
import SampleResponse from "../SimpleCodeWindow";
import StatusCodes from "../StatusCodes";
import Breadcrumb from "../Breadcrumb";
import { ArrowRight } from "lucide-react";

interface Script {
  name: string;
  language: string;
  content: string;
}

interface StatusCode {
  code: string;
  description: string;
  message: string;
}

const AlphaChannel: React.FC = () => {
  const [base64Scripts, setBase64Scripts] = useState<Script[]>([]);
  const [binaryScripts, setBinaryScripts] = useState<Script[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedLanguage, setSelectedLanguage] = useState<string>("cURL");
  const { t } = useTranslation("pages/docs/alpha");

  const sampleResponse = '{\n  "alpha_base64": "/9j/4AAQSkZJRgABAQAA..."\n}';

  const statusCodes: StatusCode[] = [
    {
      code: "200",
      description: "OK",
      message: "Success",
    },
    {
      code: "401",
      description: "Unauthorized",
      message: '{\n  "detail": "Invalid API Key"\n}',
    },
    {
      code: "402",
      description: "Unauthorized",
      message:
        '{\n  "detail": "Insufficient credit. Please top up API credits."\n}',
    },
    {
      code: "403",
      description: "Unauthorized",
      message:
        '{\n  "detail": "Credits Expired. Please top up API credits. If you have existing credits, they will be reactivated."\n}',
    },
    {
      code: "413",
      description: "Unauthorized",
      message:
        '{\n  "detail": "File size too large. Maximum file size is 10.0 MB"\n}',
    },
    {
      code: "415",
      description: "Unauthorized",
      message:
        '{\n  "detail": "Unsupported Media Type. Supported formats are: JPEG, PNG, WebP, TIFF, BMP, GIF."\n}',
    },
    {
      code: "422",
      description: "Unauthorized",
      message: '{\n  "detail": "Input couldn\'t be validated"\n}',
    },
    {
      code: "429",
      description: "Unauthorized",
      message: '{\n  "detail": Rate limit (7 request/minute) exceeded \n}',
    },
    {
      code: "500",
      description: "Unauthorized",
      message:
        '{\n  "detail": "Internal Server Error. Please contact support: contact@withoutbg.com"\n}',
    },
  ];

  useEffect(() => {
    const loadScripts = async () => {
      try {
        const base64ScriptFiles = [
          {
            name: "cURL",
            language: "bash",
            path: "/scripts/alpha/base64/curl.sh",
          },
          {
            name: "Python",
            language: "python",
            path: "/scripts/alpha/base64/python.py",
          },
          {
            name: "Java",
            language: "java",
            path: "/scripts/alpha/base64/java.java",
          },
          {
            name: "PHP",
            language: "php",
            path: "/scripts/alpha/base64/php.php",
          },
          {
            name: "Node.js",
            language: "js",
            path: "/scripts/alpha/base64/node.js",
          },
          {
            name: "Go",
            language: "go",
            path: "/scripts/alpha/base64/go.go",
          },
          {
            name: "Ruby",
            language: "ruby",
            path: "/scripts/alpha/base64/ruby.rb",
          },
          {
            name: "JavaScript (Fetch)",
            language: "js",
            path: "/scripts/alpha/base64/javascript-fetch.js",
          },
          {
            name: "JavaScript (Axios)",
            language: "js",
            path: "/scripts/alpha/base64/javascript-axios.js",
          },
        ];

        const binaryScriptFiles = [
          {
            name: "cURL",
            language: "bash",
            path: "/scripts/alpha/binary/curl.sh",
          },
          {
            name: "Python",
            language: "python",
            path: "/scripts/alpha/binary/python.py",
          },
          {
            name: "Java",
            language: "java",
            path: "/scripts/alpha/binary/java.java",
          },
          {
            name: "PHP",
            language: "php",
            path: "/scripts/alpha/binary/php.php",
          },
          {
            name: "Node.js",
            language: "js",
            path: "/scripts/alpha/binary/node.js",
          },
          {
            name: "Go",
            language: "go",
            path: "/scripts/alpha/binary/go.go",
          },
          {
            name: "Ruby",
            language: "ruby",
            path: "/scripts/alpha/binary/ruby.rb",
          },
          {
            name: "JavaScript (Fetch)",
            language: "js",
            path: "/scripts/alpha/binary/javascript-fetch.js",
          },
          {
            name: "JavaScript (Axios)",
            language: "js",
            path: "/scripts/alpha/binary/javascript-axios.js",
          },
        ];

        const loadScriptsFromFiles = async (files: any[]) => {
          return await Promise.all(
            files.map(async (file) => {
              const response = await fetch(file.path);
              if (!response.ok) {
                throw new Error(t("errors.scriptLoad", { name: file.name }));
              }
              const content = await response.text();
              return { ...file, content };
            })
          );
        };

        const [loadedBase64Scripts, loadedBinaryScripts] = await Promise.all([
          loadScriptsFromFiles(base64ScriptFiles),
          loadScriptsFromFiles(binaryScriptFiles),
        ]);

        setBase64Scripts(loadedBase64Scripts);
        setBinaryScripts(loadedBinaryScripts);
        setIsLoading(false);
      } catch (err) {
        setError(err instanceof Error ? err.message : t("errors.generic"));
        setIsLoading(false);
      }
    };

    loadScripts();
  }, [t]);

  const handleLanguageChange = (language: string) => {
    setSelectedLanguage(language);
  };

  const getSelectedScript = (scripts: Script[], language: string) => {
    return scripts.find((script) => script.name === language) || scripts[0];
  };

  const breadcrumbItems = [
    { label: t("breadcrumbs.home"), path: "/" },
    { label: t("breadcrumbs.documentation"), path: "/documentation" },
    {
      label: t("breadcrumbs.alphaChannel"),
      path: "/documentation/alpha-channel",
    },
  ];

  return (
    <>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 w-full">
        <Breadcrumb items={breadcrumbItems} />
        <h1 className="text-4xl font-bold mb-4 text-gray-900 dark:text-gray-200">
          {t("title")}
        </h1>
        <p className="mb-6 text-gray-700 dark:text-gray-300">
          {t("description")}
        </p>

        <div className="flex flex-col md:flex-row justify-between items-center mb-8 space-y-4 md:space-y-0">
          <div className="w-full md:w-[calc(50%-2rem)] flex justify-center items-center">
            <img
              src="https://media.withoutbg.com/examples/dog.jpg"
              alt={t("images.original.alt")}
              className="max-w-full max-h-[400px] w-auto h-auto object-contain"
            />
          </div>
          <div className="flex items-center justify-center w-16">
            <ArrowRight className="w-12 h-12 text-purple-900 dark:text-purple-300" />
          </div>

          <div className="w-full md:w-[calc(50%-2rem)] flex justify-center items-center">
            <img
              src="https://media.withoutbg.com/examples/dog-alpha.png"
              alt={t("images.alpha.alt")}
              className="max-w-full max-h-[400px] w-auto h-auto object-contain"
            />
          </div>
        </div>

        <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-gray-200">
          {t("sections.base64.title")}
        </h2>

        <p className="mb-6 text-gray-700 dark:text-gray-300">
          {t("sections.base64.description")}
        </p>

        <div className="w-full bg-gray-100 dark:bg-gray-800 rounded-lg overflow-hidden mb-8">
          {isLoading ? (
            <p className="p-4 text-gray-700 dark:text-gray-300">
              {t("loading")}
            </p>
          ) : error ? (
            <p className="p-4 text-red-500 dark:text-red-400">{error}</p>
          ) : (
            <CodeWindow
              scripts={base64Scripts}
              method="POST"
              target="/v1.0/alpha-channel-base64"
              selectedScript={getSelectedScript(
                base64Scripts,
                selectedLanguage
              )}
              onScriptChange={handleLanguageChange}
            />
          )}
        </div>

        <SampleResponse content={sampleResponse} title={"SAMPLE RESPONSE"} />

        <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-gray-200 pt-10">
          {t("sections.binary.title")}
        </h2>

        <p className="mb-6 text-gray-700 dark:text-gray-300">
          {t("sections.binary.description")}
        </p>

        <div className="w-full bg-gray-100 dark:bg-gray-800 rounded-lg overflow-hidden mb-8">
          {isLoading ? (
            <p className="p-4 text-gray-700 dark:text-gray-300">
              {t("loading")}
            </p>
          ) : error ? (
            <p className="p-4 text-red-500 dark:text-red-400">{error}</p>
          ) : (
            <CodeWindow
              scripts={binaryScripts}
              method="POST"
              target="/v1.0/alpha-channel"
              selectedScript={getSelectedScript(
                binaryScripts,
                selectedLanguage
              )}
              onScriptChange={handleLanguageChange}
            />
          )}
        </div>

        <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-gray-200">
          {t("sections.responseCodes.title")}
        </h2>

        <div className="pb-20">
          <StatusCodes statusCodes={statusCodes} defaultCode="200" />
        </div>
      </div>
    </>
  );
};

export default AlphaChannel;
