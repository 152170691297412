import React from "react";

import { useTranslation } from 'react-i18next';

interface LoaderProps {
  imageUrl: string;
}

const Loader: React.FC<LoaderProps> = ({ imageUrl }) => {

  const { t } = useTranslation('demo');
  return (
    <div className="bg-light dark:bg-dark transition-colors duration-200">
      <div className="relative w-full h-full">
        <div className="w-full max-w-4xl mx-auto relative overflow-hidden flex justify-center items-center">
          {/* Image container */}
          <div className="relative">
            {/* Image being processed */}
            <img
              src={imageUrl}
              alt="Processing"
              className="max-w-full max-h-full object-contain"
            />

            {/* Semi-transparent overlay directly on image */}
            <div className="absolute inset-0 bg-gray-200 dark:bg-gray-800 opacity-50"></div>

            {/* Shimmer effect only on image */}
            <div className="absolute inset-0">
              <div
                className="h-full w-full animate-shimmer bg-gradient-to-r from-transparent via-white to-transparent dark:bg-gradient-to-r dark:from-transparent dark:via-gray-300 dark:to-transparent"
                style={{ backgroundSize: "200% 100%" }}
              ></div>
            </div>
          </div>

          {/* "Processing..." text */}
          <div className="absolute inset-0 flex flex-col items-center justify-center">
            <div className="text-4xl font-extrabold text-purple-700 dark:text-purple-300 drop-shadow-md">
              {t('dragAndDrop.processing', 'Processing...')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
