import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLocalizedNavigation } from "../hooks/useLocalizedNavigation";

const FinalConversionPoint: React.FC = () => {
  const { t } = useTranslation("components/finalConversionPoint");
  const { createLocalizedLink } = useLocalizedNavigation();

  return (
    <section className="bg-gradient-to-r from-purple-100 to-pink-100 dark:from-gray-900 dark:to-gray-800 py-16">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <h2 className="text-3xl md:text-4xl font-bold mb-6 text-purple-800 dark:text-purple-200">
          {t("title")}
        </h2>
        <p className="text-lg mb-8 text-gray-700 dark:text-gray-300">
          {t("description")}
        </p>
        <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4">
          <Link
            to={createLocalizedLink("/signup")}
            className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-3 px-6 rounded-lg transition duration-300 shadow-md hover:shadow-lg inline-block"
          >
            {t("buttons.signup")}
          </Link>
          <Link
            to={createLocalizedLink("/documentation")}
            className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-3 px-6 rounded-lg transition duration-300 shadow-md hover:shadow-lg inline-block"
          >
            {t("buttons.docs")}
          </Link>
        </div>
      </div>
    </section>
  );
};

export default FinalConversionPoint;
