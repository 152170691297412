import React from "react";
import { useTranslation } from "react-i18next";
import { useLocalizedNavigation } from "../../hooks/useLocalizedNavigation";

interface TermsCheckboxProps {
  termsAccepted: boolean;
  setTermsAccepted: (accepted: boolean) => void;
}

const TermsCheckbox: React.FC<TermsCheckboxProps> = ({
  termsAccepted,
  setTermsAccepted,
}) => {
  const { t } = useTranslation("signup");
  const { createLocalizedLink } = useLocalizedNavigation();

  return (
    <div>
      <label className="flex items-center">
        <input
          type="checkbox"
          checked={termsAccepted}
          onChange={(e) => setTermsAccepted(e.target.checked)}
          className="rounded border-gray-300 text-purple-600 shadow-sm focus:border-purple-300 focus:ring focus:ring-offset-0 focus:ring-purple-200 focus:ring-opacity-50 dark:border-gray-600 dark:bg-gray-700"
          required
        />
        <span className="ml-2 text-sm text-gray-600 dark:text-gray-400">
          {t("checkboxText.prefix")}{" "}
          <a
            href={createLocalizedLink("/terms-and-conditions")}
            className="text-purple-600 hover:underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("checkboxText.terms")}
          </a>{" "}
          {t("checkboxText.and")}{" "}
          <a
            href={createLocalizedLink("/privacy-policy")}
            className="text-purple-600 hover:underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("checkboxText.privacy")}
          </a>
        </span>
      </label>
    </div>
  );
};

export default TermsCheckbox;
