import React, { useState, ReactNode } from "react";

interface TooltipProps {
  children: ReactNode;
  text: string;
}

const Tooltip: React.FC<TooltipProps> = ({ children, text }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className="relative inline-block">
      <div
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
      >
        {children}
      </div>
      {isVisible && (
        <div className="text-center absolute z-10 px-2 py-1 text-sm font-medium text-white bg-gray-900 rounded-md shadow-sm dark:bg-gray-700 left-1/2 transform -translate-x-1/2 bottom-[calc(100%+12px)]">
          {text}
          <div className="absolute w-0 h-0 border-l-[6px] border-l-transparent border-r-[6px] border-r-transparent border-t-[6px] border-t-gray-900 dark:border-t-gray-700 left-1/2 transform -translate-x-1/2 bottom-[-6px]" />
        </div>
      )}
    </div>
  );
};

export default Tooltip;
