import { MailCheckIcon } from "lucide-react";

interface SuccessMessageProps {
  messageTitle: string;
  messageDescription: string;
}

const SuccessMessage: React.FC<SuccessMessageProps> = ({
  messageTitle,
  messageDescription,
}) => (
  <div className="flex flex-col items-center justify-center h-full">
    <MailCheckIcon size={64} className="text-green-600 mb-4" />
    <h2 className="text-3xl font-bold text-center text-gray-800 dark:text-gray-200 mb-2">
      {messageTitle}
    </h2>
    <p className="text-xl text-center text-gray-600 dark:text-gray-400">
      {messageDescription}
    </p>
  </div>
);

export default SuccessMessage;
