import React, { useState } from "react";

interface BackgroundImagePickerProps {
  onClose: () => void;
  onImageSelect: (imageUrl: string) => void;
}

const BackgroundImagePicker: React.FC<BackgroundImagePickerProps> = ({
  onClose,
  onImageSelect,
}) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const thumbnails = [
    `${process.env.PUBLIC_URL}/images/thumbnail-kite.jpg`,
    `${process.env.PUBLIC_URL}/images/thumbnail-clouds.jpg`,
  ];

  const fullSizeImages = [
    `${process.env.PUBLIC_URL}/images/kite.jpg`,
    `${process.env.PUBLIC_URL}/images/clouds.jpg`,
  ];

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const result = reader.result as string;
        setSelectedImage(result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleConfirm = () => {
    if (selectedImage) {
      onImageSelect(selectedImage);
    }
    onClose();
  };

  const handleThumbnailClick = (index: number) => {
    setSelectedImage(fullSizeImages[index]);
  };

  return (
    <div className="absolute z-20 mt-2 p-4 bg-white rounded-lg shadow-xl">
      <div className="grid grid-cols-2 gap-2 mb-4">
        {thumbnails.map((thumb, index) => (
          <img
            key={index}
            src={thumb}
            alt={`Thumbnail ${index + 1}`}
            className={`w-24 h-24 object-cover cursor-pointer ${
              selectedImage === fullSizeImages[index]
                ? "border-2 border-blue-500"
                : ""
            }`}
            onClick={() => handleThumbnailClick(index)}
          />
        ))}
      </div>
      <div className="mb-4">
        <input
          type="file"
          accept="image/*"
          onChange={handleFileUpload}
          className="hidden"
          id="bgImageUpload"
        />
        <label
          htmlFor="bgImageUpload"
          className="bg-gray-200 hover:bg-gray-300 px-4 py-2 rounded cursor-pointer"
        >
          Upload Image
        </label>
      </div>
      <div className="flex justify-between">
        <button
          onClick={onClose}
          className="bg-gray-200 hover:bg-gray-300 px-4 py-2 rounded"
        >
          Cancel
        </button>
        <button
          onClick={handleConfirm}
          className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
          disabled={!selectedImage}
        >
          Confirm
        </button>
      </div>
    </div>
  );
};

export default BackgroundImagePicker;
