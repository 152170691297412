import React from "react";

interface BeforeAfterComparisonProps {
  beforeImage: string;
  afterImage: string;
  background: "checkerboard" | "color" | "image";
  backgroundColor: string;
  backgroundImage: string | null;
}

const BeforeAfterComparison: React.FC<BeforeAfterComparisonProps> = ({
  beforeImage,
  afterImage,
  background,
  backgroundColor,
  backgroundImage,
}) => {
  return (
    <div className="flex w-full max-w-4xl mx-auto">
      <div className="w-1/2 pr-2">
        <img src={beforeImage} alt="Before" className="w-full h-auto" />
        <p className="text-center mt-2">Before</p>
      </div>
      <div className="w-1/2 pl-2">
        <div
          className={`relative ${
            background === "checkerboard"
              ? "bg-checkerboard bg-checkerboard-size bg-checkerboard-repeat"
              : ""
          }`}
          style={background === "color" ? { backgroundColor } : {}}
        >
          <img src={afterImage} alt="After" className="w-full h-auto" />
        </div>
        <p className="text-center mt-2">After</p>
      </div>
    </div>
  );
};

export default BeforeAfterComparison;
