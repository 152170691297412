// src/components/SecondaryNavbarContext.tsx
import React, { createContext, useContext, useState, ReactNode } from "react";

interface SecondaryNavbarContextProps {
  content: React.ReactNode;
  setContent: React.Dispatch<React.SetStateAction<React.ReactNode>>;
}

interface SecondaryNavbarProviderProps {
  children: ReactNode;
}

const SecondaryNavbarContext = createContext<
  SecondaryNavbarContextProps | undefined
>(undefined);

export const SecondaryNavbarProvider = ({
  children,
}: SecondaryNavbarProviderProps) => {
  const [content, setContent] = useState<React.ReactNode>(null);

  return (
    <SecondaryNavbarContext.Provider value={{ content, setContent }}>
      {children}
    </SecondaryNavbarContext.Provider>
  );
};

export const useSecondaryNavbar = () => {
  const context = useContext(SecondaryNavbarContext);
  if (context === undefined) {
    throw new Error(
      "useSecondaryNavbar must be used within a SecondaryNavbarProvider"
    );
  }
  return context;
};
