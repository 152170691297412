import React, { useEffect, useState, useRef, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext/AuthContext";
import config from "../../config";

const Login: React.FC = () => {
  const [message, setMessage] = useState<string>("");
  const [error, setError] = useState<string>("");
  const location = useLocation();
  const navigate = useNavigate();
  const isLoggingInRef = useRef(false);
  const { isAuthenticated, setToken } = useAuth();

  const completeLogin = useCallback(async () => {
    if (isLoggingInRef.current) return;
    isLoggingInRef.current = true;

    const params = new URLSearchParams(location.search);
    const token = params.get("token");

    if (!token) {
      setError("Invalid login link");
      isLoggingInRef.current = false;
      return;
    }

    try {
      const response = await fetch(`${config.API_URL}/login?token=${token}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Login failed");
      }

      const data = await response.json();
      setMessage("Logging in...");

      // Use the setToken function from AuthContext
      setToken(data.access_token);

      // Redirect to the account page or dashboard after a short delay
      setTimeout(() => navigate("/account"), 500);
    } catch (error) {
      setError("Login failed. Please try again.");
    } finally {
      isLoggingInRef.current = false;
    }
  }, [location.search, navigate, setToken]);

  useEffect(() => {
    completeLogin();
  }, [completeLogin]);

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/account");
    }
  }, [isAuthenticated, navigate]);

  return (
    <div className="flex items-center justify-center min-h-screen bg-light dark:bg-dark">
      <div className="p-8 bg-light dark:bg-dark">
        {message && (
          <p className="text-purple-600 font-bold text-4xl">{message}</p>
        )}
        {error && <p className="text-red-600 text-xl">{error}</p>}
        {!message && !error && (
          <p className="text-gray-600 text-xl">Logging in...</p>
        )}
      </div>
    </div>
  );
};

export default Login;
