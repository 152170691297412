// components/LoadingSpinner.tsx
import React from 'react';

const LoadingSpinner: React.FC = () => {
    return (
        <div className="flex items-center justify-center min-h-screen">
            <div className="w-12 h-12 border-4 border-purple-200 rounded-full animate-spin border-t-purple-800"></div>
        </div>
    );
};

export default LoadingSpinner;