import React from "react";

const LoadingAnimation: React.FC<{ dotColor?: string }> = ({
  dotColor = "bg-purple-500",
}) => {
  return (
    <div className="flex items-center justify-center space-x-2 h-24">
      {[0, 1, 2].map((index) => (
        <div
          key={index}
          className={`w-5 h-5 ${dotColor} rounded-full animate-bounce`}
          style={{ animationDelay: `${index * 0.1}s` }}
        ></div>
      ))}
    </div>
  );
};

export default LoadingAnimation;
