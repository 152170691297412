import React, { useState, useRef } from "react";
import { useFloating, offset, flip, shift, arrow } from "@floating-ui/react";

interface ColorPickerProps {
  onClose: () => void;
  onColorSelect: (color: string) => void;
  anchorEl: HTMLElement | null;
}

const ColorPicker: React.FC<ColorPickerProps> = ({
  onClose,
  onColorSelect,
  anchorEl,
}) => {
  const [hexColor, setHexColor] = useState("#FFFFFF");
  const arrowRef = useRef(null);

  const { x, y, strategy, refs } = useFloating({
    elements: {
      reference: anchorEl,
    },
    placement: "bottom-start",
    middleware: [offset(10), flip(), shift(), arrow({ element: arrowRef })],
  });

  const predefinedColors = [
    "#FF8A8A",
    "#F0A8D0",
    "#B1AFFF",
    "#9BB0C1",
    "#254336",
    "#948979",
    "#FFDC7F",
    "#5356FF",
    "#ECE3CE",
    "#A25772",
    "#7A316F",
    "#35155D",
  ];

  const handleHexChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHexColor(e.target.value);
  };

  const handleColorSelect = (color: string) => {
    onColorSelect(color);
  };

  if (!anchorEl) return null;

  return (
    <div
      ref={refs.setFloating}
      style={{
        position: strategy,
        top: y ?? 0,
        left: x ?? 0,
        width: "max-content",
      }}
      className="z-50 p-4 bg-gray-100 rounded-lg shadow-xl"
    >
      <div
        ref={arrowRef}
        className="absolute bg-gray-100 w-3 h-3 rotate-45 -top-1.5 left-5"
      />
      <div className="grid grid-cols-4 gap-2 mb-4">
        {predefinedColors.map((color) => (
          <button
            key={color}
            className="w-8 h-8 rounded-md"
            style={{ backgroundColor: color }}
            onClick={() => handleColorSelect(color)}
          />
        ))}
      </div>
      <div className="flex items-center">
        <input
          type="text"
          value={hexColor}
          onChange={handleHexChange}
          className="border rounded px-2 py-1 w-24 mr-2"
        />
        <button
          onClick={() => handleColorSelect(hexColor)}
          className="bg-purple-700 text-white px-2 py-1 rounded"
        >
          Apply
        </button>
      </div>
      <button
        onClick={onClose}
        className="mt-4 text-sm text-gray-500 hover:text-gray-700"
      >
        Close
      </button>
    </div>
  );
};

export default ColorPicker;
