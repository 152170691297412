import React, { useState, useEffect } from "react";
import { EyeIcon, EyeOffIcon, CopyIcon, CheckIcon } from "lucide-react";
import { useTranslation } from "react-i18next";

interface ApiKeyDisplayProps {
  apiKey: string;
  credits: number;
  expirationDate: string;
}

const CompactDigitalCounter = ({ credits }: { credits: number }) => {
  const getDigits = (number: number): string[] => {
    return number.toString().padStart(5, "0").split("");
  };
  const { t } = useTranslation("account");

  return (
    <div className="flex items-center space-x-2">
      <span className="text-xs sm:text-sm font-medium text-gray-600 dark:text-gray-400">
        {t("apiKeyBarDisplay.credits")}
      </span>
      <div className="flex space-x-0.5">
        {getDigits(credits).map((digit, index) => (
          <div
            key={index}
            className="bg-gray-200 dark:bg-gray-800 rounded px-1 py-0.5 w-6 h-8 flex items-center justify-center"
          >
            <span className="font-mono text-sm font-bold bg-gradient-to-b from-green-500 to-green-700 dark:from-green-400 dark:to-green-600 bg-clip-text text-transparent">
              {digit}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

const ApiKeyDisplay: React.FC<ApiKeyDisplayProps> = ({
  apiKey,
  credits,
  expirationDate,
}) => {
  const [showApiKey, setShowApiKey] = useState(false);
  const [copied, setCopied] = useState(false);
  const { t } = useTranslation("account");

  const toggleApiKeyVisibility = () => {
    setShowApiKey((prev) => !prev);
  };

  const copyApiKey = () => {
    navigator.clipboard.writeText(apiKey);
    setCopied(true);
  };

  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => {
        setCopied(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [copied]);

  const maskedApiKey = showApiKey
    ? apiKey
    : `${apiKey.slice(0, 7)}${"*".repeat(apiKey.length - 5)}`;

  return (
    <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between w-full px-2 sm:px-4 py-2 space-y-2 sm:space-y-0">
      <div className="flex flex-wrap items-center space-x-2">
        <span className="text-xs sm:text-sm font-medium text-purple-700 dark:text-purple-300">
          {t("apiKeyBarDisplay.yourApiKey")}
        </span>
        <div className="bg-gray-200 dark:bg-gray-900 rounded px-2 sm:px-3 py-1 text-xs sm:text-sm font-mono text-gray-800 dark:text-gray-200">
          {maskedApiKey}
        </div>
        <button
          onClick={toggleApiKeyVisibility}
          className="p-1 text-purple-600 hover:text-purple-800 dark:text-purple-400 dark:hover:text-purple-200"
          aria-label="Toggle API Key Visibility"
        >
          {showApiKey ? (
            <EyeOffIcon className="h-3 w-3 sm:h-4 sm:w-4" />
          ) : (
            <EyeIcon className="h-3 w-3 sm:h-4 sm:w-4" />
          )}
        </button>
        <button
          onClick={copyApiKey}
          className="p-1 text-purple-600 hover:text-purple-800 dark:text-purple-400 dark:hover:text-purple-200 focus:outline-none"
          aria-label="Copy API Key"
        >
          {copied ? (
            <CheckIcon className="h-3 w-3 sm:h-4 sm:w-4" />
          ) : (
            <CopyIcon className="h-3 w-3 sm:h-4 sm:w-4" />
          )}
        </button>
      </div>
      <div className="flex flex-col items-end space-y-1">
        <CompactDigitalCounter credits={credits} />
      </div>
    </div>
  );
};

export default ApiKeyDisplay;
