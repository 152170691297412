import React from "react";
import { useAuthState, AuthState } from "../hooks/useAuthState";
import Try from "./Try";
import Examples from "./Examples";
import Documentation from "./Docs/Intro";

import AlphaChannel from "./Docs/OutputTypes/AlphaChannel";
import WithoutBackground from "../pages/Docs/OutputTypes/WithoutBackground";
import Credits from "./Docs/OutputTypes/Credit";
import MainContent from "../components/MainContent";
import Account from "./Account";
import PaymentSuccess from "./PaymentSuccess";
import CompleteRegistration from "../components/Auth/CompleteSignUp";

import Login from "../components/Auth/Login";

import TermsAndConditions from "../pages/Legal/TermsAndConditions";
import PrivacyPolicy from "./Legal/PrivacyPolicy";
import Imprint from "./Legal/Imprint";
import ResourceIndex from "../pages/Resources/ResourceIndex";
import Trimap from "./Resources/Trimap";
import Withoutbg100 from "./Resources/Withoutbg100";
import Sticker from "./Resources/Sticker";
import DropShadow from "./Resources/DropShadow";
import DatasetCreation from "./Resources/CreatingDataset";

import SignUpPage from "./Auth/SignUp";

interface RouteConfig {
  path: string;
  element: React.ReactNode;
  children?: RouteConfig[];
}

// Helper function to create language-prefixed routes
const createLanguageRoutes = (routes: RouteConfig[]): RouteConfig[] => {
  return routes.map((route) => ({
    ...route,
    path: `/:lang${route.path}`,
  }));
};

// Function to generate routes with auth context
const generateRoutes = (auth: AuthState): RouteConfig[] => {
  const baseRoutes: RouteConfig[] = [
    { path: "/", element: <MainContent /> },
    { path: "/remove-background", element: <Try /> },
    { path: "/examples", element: <Examples /> },
    { path: "/documentation", element: <Documentation /> },
    {
      path: "/documentation/transparent-background",
      element: <WithoutBackground />,
    },
    { path: "/documentation/alpha-channel", element: <AlphaChannel /> },
    { path: "/documentation/credits", element: <Credits /> },
    { path: "/account", element: <Account onLoginClick={auth.openLogin} /> },
    {
      path: "/payment/success/:purchaseId",
      element: <PaymentSuccess onLoginClick={auth.openLogin} />,
    },
    { path: "/complete-registration", element: <CompleteRegistration /> },
    { path: "/signup", element: <SignUpPage onSubmit={auth.handleSignUp} /> },
    { path: "/login", element: <Login /> },
    { path: "/resources", element: <ResourceIndex /> },
    { path: "/resources/trimap", element: <Trimap /> },
    { path: "/resources/adding-drop-shadow", element: <DropShadow /> },
    {
      path: "/resources/creating-alpha-matting-dataset",
      element: <DatasetCreation />,
    },

    {
      path: "/resources/withoutbg100-image-matting-dataset",
      element: <Withoutbg100 />,
    },
    { path: "/resources/creating-sticker", element: <Sticker /> },
    { path: "/terms-and-conditions", element: <TermsAndConditions /> },
    { path: "/privacy-policy", element: <PrivacyPolicy /> },
    { path: "/imprint", element: <Imprint /> },
  ];

  return [...baseRoutes, ...createLanguageRoutes(baseRoutes)];
};

// Hook to get routes with auth state
export const useRoutes = () => {
  const auth = useAuthState();
  const routes = generateRoutes(auth);

  return {
    routes,
    auth,
    validPaths: routes
      .filter((route) => !route.path.includes(":lang"))
      .map((route) => route.path.slice(1)),
  };
};
