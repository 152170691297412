import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { validateEmail } from "../../utils/validators";
import Coordinate from "../../components/Captcha/Coordinate";
import { useCaptchaChallenge } from "../../hooks/useCaptchaChallenge";
import { useSignUp } from "../../hooks/useSignUp";
import EmailInput from "../../components/Auth/EmailInput";
import TermsCheckbox from "../../components/Auth/TermsCheckbox";
import CaptchaChallengeComponent from "../../components/Captcha/Challenge";
import SubmitButton from "../../components/Auth/SignUpSubmitButton";
import SuccessMessage from "../../components/Auth/SuccessMessage";
import LoadingAnimation from "../../components/Captcha/LoadingAnimation";
import RegistrationTimeline from "../../components/Auth/RegistrationTimeline";

interface SignUpPageProps {
  onSubmit: (email: string) => Promise<void>;
}

const SignUpPage: React.FC<SignUpPageProps> = ({ onSubmit }) => {
  const { t } = useTranslation("signup");
  const [email, setEmail] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [clickedCoordinates, setClickedCoordinates] = useState<Coordinate[]>(
    []
  );
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isCaptchaReady, setIsCaptchaReady] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [showTimeline, setShowTimeline] = useState(true);

  const {
    captchaChallenge,
    isLoading: isCaptchaLoading,
    fetchCaptchaChallenge,
  } = useCaptchaChallenge();

  const {
    message,
    error,
    isSubmitting,
    isSuccess,
    handleSubmit: originalHandleSubmit,
  } = useSignUp(email, captchaChallenge, clickedCoordinates, onSubmit);

  const getErrorMessage = (errorCode: string) => {
    switch (errorCode) {
      case "INVALID_CAPTCHA":
        return t("errors.invalidCaptcha");
      case "USER_EXISTS":
        return t("errors.userExists");
      default:
        return t("errors.default");
    }
  };

  const resetCaptcha = () => {
    setClickedCoordinates([]);
    setCurrentImageIndex(0);
    fetchCaptchaChallenge();
  };

  useEffect(() => {
    if (error === "INVALID_CAPTCHA") {
      resetCaptcha();
    }
  }, [error]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await originalHandleSubmit(e);
  };

  useEffect(() => {
    if (isSuccess) {
      setCurrentStep((prevStep) => prevStep + 1);
      const timer = setTimeout(() => {
        setShowTimeline(false);
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [isSuccess]);

  useEffect(() => {
    setIsValidEmail(validateEmail(email));
  }, [email]);

  useEffect(() => {
    if (isValidEmail && termsAccepted && !captchaChallenge) {
      fetchCaptchaChallenge();
    }
  }, [isValidEmail, termsAccepted, captchaChallenge, fetchCaptchaChallenge]);

  useEffect(() => {
    if (captchaChallenge && !isCaptchaLoading) {
      setIsCaptchaReady(true);
    }
  }, [captchaChallenge, isCaptchaLoading]);

  const handleImageClick = (e: React.MouseEvent<HTMLImageElement>) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const x = Math.round(e.clientX - rect.left);
    const y = Math.round(e.clientY - rect.top);
    setClickedCoordinates((prev) => [...prev, { x, y }]);
    if (currentImageIndex < 4) {
      setTimeout(() => setCurrentImageIndex((prev) => prev + 1), 500);
    }
  };

  const renderForm = () => (
    <>
      <h1 className="text-2xl font-bold mb-6 text-center text-gray-700 dark:text-gray-100">
        {t("title")}
      </h1>
      <form onSubmit={handleSubmit} className="space-y-6">
        <EmailInput email={email} setEmail={setEmail} />
        <TermsCheckbox
          termsAccepted={termsAccepted}
          setTermsAccepted={setTermsAccepted}
        />
        {isValidEmail &&
          termsAccepted &&
          (isCaptchaReady && captchaChallenge ? (
            <CaptchaChallengeComponent
              captchaChallenge={captchaChallenge}
              currentImageIndex={currentImageIndex}
              isLoading={isCaptchaLoading}
              handleImageClick={handleImageClick}
              clickedCoordinates={clickedCoordinates}
            />
          ) : (
            <LoadingAnimation />
          ))}
        <SubmitButton
          isValidEmail={isValidEmail}
          termsAccepted={termsAccepted}
          clickedCoordinates={clickedCoordinates}
          isSubmitting={isSubmitting}
          isCaptchaReady={isCaptchaReady}
        />
        {error && (
          <p className="text-red-600 dark:text-red-400 text-center mt-2">
            {getErrorMessage(error)}
          </p>
        )}
      </form>
    </>
  );

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900 flex items-center justify-center p-4">
      <div
        className={`max-w-6xl w-full flex flex-col md:flex-row items-start justify-center ${
          showTimeline ? "" : "md:justify-end"
        }`}
      >
        {showTimeline && (
          <>
            <div className="w-full md:w-1/2 mb-8 md:mb-0 order-1 md:order-none">
              <RegistrationTimeline align="right" step={currentStep} />
            </div>
            <div className="hidden md:block w-px bg-gray-300 dark:bg-gray-700 self-stretch mx-4" />
          </>
        )}
        <div
          className={`w-full ${
            showTimeline ? "md:w-1/2" : "md:w-full"
          } md:pl-8 order-2 md:order-none`}
        >
          {isSuccess ? (
            <SuccessMessage
              messageTitle={t("successMessage.title")}
              messageDescription={t("successMessage.description")}
            />
          ) : (
            renderForm()
          )}
        </div>
      </div>
    </div>
  );
};

export default SignUpPage;
