import React, { useState, useRef, useEffect } from "react";
import { Download, RotateCcw } from "lucide-react";
import Tooltip from "./Tooltip";
import ColorPicker from "./ColorPicker";
import BackgroundImagePicker from "./BackgroundImgPicker";
import { useTranslation } from 'react-i18next';

interface TopActionBarProps {
  onBackgroundChange: (
    type: "checkerboard" | "color" | "image",
    value?: string
  ) => void;
  onBeforeAfterChange: (showBefore: boolean) => void;
  showBefore: boolean;
  onDownload: () => void;
  onTryAnotherImage: () => void;
}

const TopActionBar: React.FC<TopActionBarProps> = ({
  onBackgroundChange,
  onBeforeAfterChange,
  showBefore,
  onDownload,
  onTryAnotherImage,
}) => {
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
  const [isImagePickerOpen, setIsImagePickerOpen] = useState(false);
  const colorPickerButtonRef = useRef<HTMLButtonElement>(null);
  const colorPickerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation('demo');

  const handleBackgroundChange = (
    type: "checkerboard" | "color" | "image",
    value?: string
  ) => {
    if (showBefore) {
      onBeforeAfterChange(false);
    }
    onBackgroundChange(type, value);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        isColorPickerOpen &&
        colorPickerRef.current &&
        !colorPickerRef.current.contains(event.target as Node) &&
        !colorPickerButtonRef.current?.contains(event.target as Node)
      ) {
        setIsColorPickerOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isColorPickerOpen]);

  return (
    <div className="flex justify-between items-center p-4 w-full max-w-4xl mx-auto">
      <div className="flex space-x-2">
        <Tooltip text={t('outputImage.buttons.transparentBackground')}>
          <button
            onClick={() => handleBackgroundChange("checkerboard")}
            className="p-2 bg-gray-200 dark:bg-gray-800 hover:bg-gray-300 dark:hover:bg-gray-700 rounded text-gray-700 dark:text-gray-300"
          >
            <div className="w-6 h-6 bg-checkerboard bg-contain bg-center bg-no-repeat border-slate-500" />
          </button>
        </Tooltip>


        <Tooltip text={t('outputImage.buttons.solidBackground')}>
          <button
            ref={colorPickerButtonRef}
            onClick={() => setIsColorPickerOpen(!isColorPickerOpen)}
            className="p-2 bg-gray-200 dark:bg-gray-800 hover:bg-gray-300 dark:hover:bg-gray-700 rounded text-gray-700 dark:text-gray-300"
          >
            <div className="w-6 h-6 bg-colorwheel rounded-full" />
          </button>

        </Tooltip>
        <Tooltip text={t('outputImage.buttons.download')}>
          <button
            onClick={onDownload}
            className="p-2 bg-gray-200 dark:bg-gray-800 hover:bg-gray-300 dark:hover:bg-gray-700 rounded text-gray-700 dark:text-gray-300"
            aria-label="Download"
          >
            <Download className="w-6 h-6 text-purple-800 dark:text-purple-200" />
          </button>
        </Tooltip>

        <Tooltip text={t('outputImage.buttons.restart')}>
          <button
            onClick={onTryAnotherImage}
            className="p-2 bg-gray-200 dark:bg-gray-800 hover:bg-gray-300 dark:hover:bg-gray-700 rounded text-gray-700 dark:text-gray-300"
            aria-label="Try Another Image"
          >
            <RotateCcw className="w-6 h-6 text-purple-800 dark:text-purple-200" />
          </button>
        </Tooltip>

        {isColorPickerOpen && (
          <div ref={colorPickerRef}>
            <ColorPicker
              onClose={() => setIsColorPickerOpen(false)}
              onColorSelect={(color) => {
                handleBackgroundChange("color", color);
                setIsColorPickerOpen(false);
              }}
              anchorEl={colorPickerButtonRef.current}
            />
          </div>
        )}
      </div>
      <div>
        <button
          onClick={() => onBeforeAfterChange(true)}
          className={`px-4 py-2 rounded-l-md ${showBefore
            ? "bg-purple-500 text-white"
            : "bg-gray-200 dark:bg-gray-600 text-gray-700 dark:text-gray-300 dark:hover:bg-purple-700 hover:bg-purple-200"
            }`}
        >
          {t('outputImage.topActionBar.before')}

        </button>
        <button
          onClick={() => onBeforeAfterChange(false)}
          className={`px-4 py-2 rounded-r-md ${!showBefore
            ? "bg-purple-500 text-white"
            : "bg-gray-200 dark:bg-gray-600 text-gray-700 dark:text-gray-300 dark:hover:bg-purple-700 hover:bg-purple-200"
            }`}
        >
          {t('outputImage.topActionBar.after')}
        </button>
      </div>
      {isImagePickerOpen && (
        <BackgroundImagePicker
          onClose={() => setIsImagePickerOpen(false)}
          onImageSelect={(imageUrl) => {
            handleBackgroundChange("image", imageUrl);
            setIsImagePickerOpen(false);
          }}
        />
      )}
    </div>
  );
};

export default TopActionBar;