import React from "react";
import { Link } from "react-router-dom";
import ApiKeyDisplay from "./ApiKeyDisplay";
import StaticDigitalCounter from "./CreditCounter";
import { useTranslation } from "react-i18next";

import { useLocalizedNavigation } from "../../hooks/useLocalizedNavigation";

interface SummaryProps {
  apiKey: string;
  credits: number;
  expiryDate: string;
}

const Summary: React.FC<SummaryProps> = ({ apiKey, credits, expiryDate }) => {
  const { t } = useTranslation("account");
  const { createLocalizedLink } = useLocalizedNavigation();

  return (
    <div className="flex flex-col items-center space-y-6 w-full max-w-3xl mx-auto p-4">
      <div className="w-full">
        <div className="w-full mb-10">
          <ApiKeyDisplay apiKey={apiKey} />
        </div>
        <StaticDigitalCounter
          credits={credits}
          expiryDate={expiryDate}
          className="w-full"
        />
        <div className="flex flex-col items-center space-y-4 w-full mt-10">
          <Link
            to={createLocalizedLink("/documentation")}
            className="inline-flex items-center px-6 py-3 rounded-md bg-orange-700 text-white font-medium transition-colors hover:bg-orange-800 focus:outline-none focus:ring-2 focus:ring-orange-700 focus:ring-offset-2 dark:bg-orange-800 dark:hover:bg-orange-900 dark:focus:ring-orange-300"
          >
            {t("startIntegrationButton")}
            <span className="ml-2">→</span>
          </Link>

          {/* Add link to contact support page 
          <Link
            to="/documentation"
            className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300 transition-colors"
          >
            Need help? Contact support
          </Link>
          */}
        </div>
      </div>
    </div>
  );
};

export default Summary;
