import React from "react";
import Coordinate from "../Captcha/Coordinate";
import { useTranslation } from "react-i18next";

interface SubmitButtonProps {
  isValidEmail: boolean;
  isValidMessage: boolean;
  clickedCoordinates: Coordinate[];
  isSubmitting: boolean;
}

const ContactSubmitButton: React.FC<SubmitButtonProps> = ({
  isValidEmail,
  isValidMessage,
  clickedCoordinates,
  isSubmitting,
}) => {
  const { t } = useTranslation("components/contact");

  return (
    <button
      type="submit"
      className="w-full bg-purple-600 text-white p-2 rounded hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed dark:bg-purple-500 dark:hover:bg-purple-600 dark:focus:ring-purple-400"
      disabled={
        !isValidEmail ||
        !isValidMessage ||
        clickedCoordinates.length < 5 ||
        isSubmitting
      }
    >
      {isSubmitting ? t("submit.sending") : t("submit.default")}
    </button>
  );
};

export default ContactSubmitButton;
