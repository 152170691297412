import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SEOHead from "../../components/SEOHead";
import SimpleCodeWindow from "../../components/Documentation/SimpleCodeWindow";

const DropShadows = () => {
    const { t } = useTranslation("resources/dropShadow");
    const [pythonCode, setPythonCode] = useState("");

    useEffect(() => {
        // Load the Python file content
        const fetchPythonCode = async () => {
            try {
                const response = await fetch("/resources/dropshadow/example.py");
                if (!response.ok) {
                    throw new Error("Failed to load Python code");
                }
                const code = await response.text();
                setPythonCode(code);
            } catch (error) {
                console.error("Error loading Python code:", error);
                setPythonCode("# Error loading code sample");
            }
        };

        fetchPythonCode();
    }, []);




    return (
        <>
            <SEOHead
                title={t("meta.title")}
                description={t("meta.description")}
                imageUrl="https://withoutbg.com/drop-shadows-documentation-og.png"
            />

            <div className="min-h-screen bg-white dark:bg-gray-900">
                <main className="max-w-4xl mx-auto px-4 py-12">
                    {/* Main Title */}
                    <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-8 mt-10">
                        {t("mainTitle")}
                    </h1>

                    {/* Introduction */}
                    <section className="mb-12">
                        <p className="text-lg text-gray-700 dark:text-gray-300">
                            {t("introContent")}
                        </p>
                    </section>

                    {/* Sample Images Section */}
                    <section className="mb-12">
                        <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
                            {t("sampleImages.title")}
                        </h2>
                        <p className="text-gray-700 dark:text-gray-300 mb-6">
                            {t("sampleImages.content")}
                        </p>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            {t("sampleImages.images", { returnObjects: true }).map((image, index) => (
                                <div key={index} className="rounded-lg overflow-hidden shadow-lg border border-gray-200 dark:border-gray-700">
                                    <img
                                        src={image.url}
                                        alt={image.alt}
                                        className="w-full h-auto"
                                        loading="lazy"
                                    />
                                </div>
                            ))}
                        </div>
                    </section>

                    {/* Process Steps Section */}
                    <section className="mb-12">
                        <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
                            {t("processSteps.title")}
                        </h2>
                        <ul className="list-decimal pl-6 mb-6 space-y-2">
                            {t("processSteps.steps", { returnObjects: true }).map((step, index) => (
                                <li key={index} className="text-gray-700 dark:text-gray-300">
                                    {step}
                                </li>
                            ))}
                        </ul>
                        <div className="rounded-lg overflow-hidden shadow-lg border border-gray-200 dark:border-gray-700">
                            <img
                                src={t("processSteps.demoImage.url")}
                                alt={t("processSteps.demoImage.alt")}
                                className="w-full h-auto"
                                loading="lazy"
                            />
                        </div>
                    </section>

                    {/* Implementation Details Section */}
                    <section className="mb-12">
                        <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
                            {t("implementationDetails.title")}
                        </h2>
                        <div className="space-y-8">
                            {t("implementationDetails.implementations", { returnObjects: true }).map((impl, index) => (
                                <div key={index} className="rounded-lg bg-gray-50 dark:bg-gray-800 p-6">
                                    <h3 className="text-2xl font-bold text-gray-900 dark:text-white mb-3">
                                        {impl.title}
                                    </h3>
                                    <p className="text-gray-700 dark:text-gray-300 mb-4">
                                        {impl.description}
                                    </p>
                                    {impl.image && (
                                        <div className="rounded-lg overflow-hidden shadow-lg border border-gray-200 dark:border-gray-700">
                                            <img
                                                src={impl.image.url}
                                                alt={impl.image.alt}
                                                className="w-full h-auto"
                                                loading="lazy"
                                            />
                                        </div>
                                    )}
                                    {impl.codepenLink && (
                                        <a
                                            href={impl.codepenLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="inline-block mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors duration-200"
                                        >
                                            View on CodePen
                                        </a>
                                    )}
                                </div>
                            ))}
                        </div>
                    </section>

                    <SimpleCodeWindow
                        content={pythonCode}
                        language="python"
                        title="Generate Trimap"
                        showLineNumbers={true}
                        wrapLongLines={true}
                        className="my-4"
                    />
                </main>

                {/* Back to Top Button */}
                <button
                    onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
                    className="fixed bottom-8 right-8 bg-blue-500 hover:bg-blue-600 text-white p-3 rounded-full shadow-lg transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2 dark:focus:ring-offset-gray-900"
                    aria-label="Scroll to top"
                >
                    <svg
                        className="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 10l7-7m0 0l7 7m-7-7v18"
                        />
                    </svg>
                </button>
            </div>
        </>
    );
};

export default DropShadows;