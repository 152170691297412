import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSecondaryNavbar } from "../../components/SecondaryNavbarContext";
import SEOHead from "../../components/SEOHead";
import {
  AuthContent,
  UnauthContent,
} from "../../components/SecondaryNavbars/ApiKeyAndCredits";
import SimpleCodeWindow from "../../components/Documentation/SimpleCodeWindow";

const Trimap: React.FC = () => {
  const { setContent } = useSecondaryNavbar();
  const { t } = useTranslation("resources/trimap");
  const [pythonCode, setPythonCode] = useState<string>("");

  useEffect(() => {
    // Load the Python file content
    const fetchPythonCode = async () => {
      try {
        const response = await fetch("/resources/trimap/example.py");
        if (!response.ok) {
          throw new Error("Failed to load Python code");
        }
        const code = await response.text();
        setPythonCode(code);
      } catch (error) {
        console.error("Error loading Python code:", error);
        setPythonCode("# Error loading code sample");
      }
    };

    fetchPythonCode();
  }, []);

  return (
    <>
      <SEOHead
        title={t("meta.title")}
        description={t("meta.description")}
        imageUrl="https://withoutbg.com/trimap-documentation-og.png"
      />

      <div className="min-h-screen bg-white dark:bg-gray-900">
        <main className="max-w-4xl mx-auto px-4 py-12">
          {/* Main Title */}
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-8 mt-10">
            {t("main_title")}
          </h1>

          {/* Introduction */}
          <section className="mb-12">
            <p className="text-lg text-gray-700 dark:text-gray-300">
              {t("intro.content")}
            </p>
          </section>

          {/* Trimaps Section */}
          <section className="mb-12">
            <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
              {t("trimaps.title")}
            </h2>
            <p className="text-gray-700 dark:text-gray-300 leading-relaxed">
              {t("trimaps.content")}
            </p>
          </section>

          {/* Example Trimap Section */}
          <section className="mb-12">
            <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
              {t("example.title")}
            </h2>
            <p className="text-gray-700 dark:text-gray-300 mb-4">
              {t("example.content")}
            </p>
            <div className="rounded-lg overflow-hidden shadow-lg border border-gray-200 dark:border-gray-700">
              <img
                src={t("example.image.url")}
                alt={t("example.image.alt")}
                className="w-full h-auto"
                loading="lazy"
              />
            </div>
          </section>

          {/* Sample Application Section */}
          <section className="mb-12">
            <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
              {t("sample_application.title")}
            </h2>
            <p className="text-gray-700 dark:text-gray-300 mb-4">
              {t("sample_application.content")}
            </p>
            <div className="rounded-lg overflow-hidden shadow-lg border border-gray-200 dark:border-gray-700">
              <img
                src={t("sample_application.image.url")}
                alt={t("sample_application.image.alt")}
                className="w-full h-auto"
                loading="lazy"
              />
            </div>
          </section>

          {/* Generating Trimap Section */}
          <section className="mb-12">
            <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
              {t("generating_trimap.title")}
            </h2>
            <div className="prose dark:prose-invert max-w-none">
              <p className="text-gray-700 dark:text-gray-300 leading-relaxed whitespace-pre-line">
                {t("generating_trimap.content")}
              </p>
            </div>
          </section>
          <SimpleCodeWindow
            content={pythonCode}
            language="python"
            title="Generate Trimap"
            showLineNumbers={true}
            wrapLongLines={true}
            className="my-4"
          />
        </main>

        {/* Back to Top Button */}
        <button
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          className="fixed bottom-8 right-8 bg-blue-500 hover:bg-blue-600 text-white p-3 rounded-full shadow-lg transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2 dark:focus:ring-offset-gray-900"
          aria-label="Scroll to top"
        ></button>
      </div>
    </>
  );
};

export default Trimap;
