import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { validateEmail } from "../../utils/validators";
import CaptchaChallenge from "../Captcha/Challenge";
import Coordinate from "../Captcha/Coordinate";
import { useCaptchaChallenge } from "../../hooks/useCaptchaChallenge";
import { useContact } from "../../hooks/useContact";
import EmailInput from "../Auth/EmailInput";
import MessageInput from "../Contact/MessageInput";
import ContactSubmitButton from "../Contact/ContactSubmitButton";
import CloseButton from "../Auth/CloseButton";
import SuccessMessage from "../Auth/SuccessMessage";
import LoadingAnimation from "../Captcha/LoadingAnimation";

interface ContactPopoverProps {
  isOpen: boolean;
  onClose: () => void;
}

const ContactPopover: React.FC<ContactPopoverProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation("components/contact");
  const [clickedCoordinates, setClickedCoordinates] = useState<Coordinate[]>(
    []
  );
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isValidMessage, setIsValidMessage] = useState(false);
  const [isCaptchaReady, setIsCaptchaReady] = useState(false);

  const {
    captchaChallenge,
    isLoading: isCaptchaLoading,
    fetchCaptchaChallenge,
  } = useCaptchaChallenge();

  const {
    email,
    setEmail,
    message,
    setMessage,
    responseMessage,
    error,
    isSubmitting,
    isSuccess,
    handleSubmit,
  } = useContact("", "", captchaChallenge, clickedCoordinates);

  useEffect(() => {
    setIsValidEmail(validateEmail(email));
  }, [email]);

  useEffect(() => {
    setIsValidMessage(message.length >= 16);
  }, [message]);

  useEffect(() => {
    if (isValidEmail && isValidMessage && !captchaChallenge) {
      fetchCaptchaChallenge();
    }
  }, [isValidEmail, isValidMessage, captchaChallenge, fetchCaptchaChallenge]);

  useEffect(() => {
    if (captchaChallenge && !isCaptchaLoading) {
      setIsCaptchaReady(true);
    }
  }, [captchaChallenge, isCaptchaLoading]);

  const handleImageClick = (e: React.MouseEvent<HTMLImageElement>) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const x = Math.round(e.clientX - rect.left);
    const y = Math.round(e.clientY - rect.top);

    setClickedCoordinates((prev) => [...prev, { x, y }]);

    if (currentImageIndex < 4) {
      setTimeout(() => setCurrentImageIndex((prev) => prev + 1), 500);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 dark:bg-opacity-70 flex items-center justify-center z-50">
      <div className="bg-white dark:bg-gray-800 p-10 rounded-lg shadow-lg w-[600px] transition-all duration-300 ease-in-out relative overflow-y-auto">
        <CloseButton onClose={onClose} />
        {isSuccess ? (
          <SuccessMessage
            messageTitle={t("success.title")}
            messageDescription={t("success.description")}
          />
        ) : (
          <ContactForm
            t={t}
            email={email}
            setEmail={setEmail}
            message={message}
            setMessage={setMessage}
            captchaChallenge={captchaChallenge}
            currentImageIndex={currentImageIndex}
            isCaptchaLoading={isCaptchaLoading}
            isCaptchaReady={isCaptchaReady}
            handleImageClick={handleImageClick}
            clickedCoordinates={clickedCoordinates}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            isValidEmail={isValidEmail}
            isValidMessage={isValidMessage}
            error={error}
          />
        )}
      </div>
    </div>
  );
};

interface ContactFormProps {
  t: (key: string) => string;
  email: string;
  setEmail: (email: string) => void;
  message: string;
  setMessage: (message: string) => void;
  captchaChallenge: CaptchaChallenge | null;
  currentImageIndex: number;
  isCaptchaLoading: boolean;
  isCaptchaReady: boolean;
  handleImageClick: (e: React.MouseEvent<HTMLImageElement>) => void;
  clickedCoordinates: Coordinate[];
  handleSubmit: (e: React.FormEvent) => Promise<void>;
  isSubmitting: boolean;
  isValidEmail: boolean;
  isValidMessage: boolean;
  error: string;
}

const ContactForm: React.FC<ContactFormProps> = ({
  t,
  email,
  setEmail,
  message,
  setMessage,
  captchaChallenge,
  currentImageIndex,
  isCaptchaLoading,
  isCaptchaReady,
  handleImageClick,
  clickedCoordinates,
  handleSubmit,
  isSubmitting,
  isValidEmail,
  isValidMessage,
  error,
}) => (
  <>
    <h2 className="text-2xl mb-4 text-gray-800 dark:text-gray-200">
      {t("title")}
    </h2>
    <p className="text-sm text-gray-600 dark:text-gray-400 mb-5">
      {t("subtitle")}
    </p>
    <form onSubmit={handleSubmit} className="space-y-4">
      <EmailInput email={email} setEmail={setEmail} />
      <MessageInput message={message} setMessage={setMessage} />
      {isValidEmail &&
        isValidMessage &&
        (isCaptchaReady && captchaChallenge ? (
          <CaptchaChallenge
            captchaChallenge={captchaChallenge}
            currentImageIndex={currentImageIndex}
            isLoading={isCaptchaLoading}
            handleImageClick={handleImageClick}
            clickedCoordinates={clickedCoordinates}
          />
        ) : (
          <LoadingAnimation />
        ))}
      <ContactSubmitButton
        isValidEmail={isValidEmail}
        isValidMessage={isValidMessage}
        clickedCoordinates={clickedCoordinates}
        isSubmitting={isSubmitting}
      />
    </form>
    {error && (
      <div className="mt-4 p-3 bg-red-100 border border-red-400 text-red-700 rounded">
        <p className="font-bold">Error:</p>
        <p>{error}</p>
      </div>
    )}
  </>
);

export default ContactPopover;
