import React from "react";
import { useTranslation } from "react-i18next";
import SEOHead from "./SEOHead";
import HeroSection from "../components/HeroSection";
import TabbedGallery from "../components/Gallery/TabbedGallery";

import Pricing from "../components/Pricing";
import FinalConversionPoint from "./FinalConversionPoint";
import FAQ from "../components/Faq";
import Privacy from "./Privacy";

const MainContent: React.FC = () => {
  const { t } = useTranslation("pages/home");

  return (
    <>
      <SEOHead
        title={t("meta.title")}
        description={t("meta.description")}
        imageUrl="https://withoutbg.com/api-documentation-og.png"
      />
      <HeroSection />
      <div className="mt-8 min-h-96 md:min-h-[500px] lg:min-h-[600px]">
        <TabbedGallery />
      </div>
      <div id="pricing" className="mt-16">
        <Pricing />
      </div>
      <FinalConversionPoint />
      <FAQ />
      <Privacy />
    </>
  );
};

export default MainContent;
