import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { SUPPORTED_LANGUAGES, DEFAULT_LANGUAGE } from "../utils/languageUtils";

interface SEOHeadProps {
  title: string;
  description: string;
  imageUrl?: string;
  noindex?: boolean;
  loadFonts?: boolean;
  schema?: Object; // Add support for structured data
  type?: string; // Content type for og:type
}

const SEOHead: React.FC<SEOHeadProps> = ({
  title,
  description,
  imageUrl = "https://withoutbg.com/default-og-image.png",
  noindex = false,
  loadFonts = false,
  schema = null,
  type = "website",
}) => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const currentLanguage = i18n.language;
  const baseUrl = "https://withoutbg.com";
  const currentPath = location.pathname;
  const strippedPath = currentPath.replace(/^\/[a-z]{2}\//, "/");
  const currentUrl = `${baseUrl}${currentPath}`;

  // Generate alternate URLs for all supported languages
  const alternateUrls = SUPPORTED_LANGUAGES.map((lang) => ({
    lang,
    url: `${baseUrl}${
      lang === DEFAULT_LANGUAGE ? strippedPath : `/${lang}${strippedPath}`
    }`,
  }));

  return (
    <HelmetProvider>
      <Helmet>
        {/* Essential Meta Tags */}
        <html lang={currentLanguage} />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta httpEquiv="content-language" content={currentLanguage} />
        <title>{title}</title>
        <meta name="description" content={description} />
        {/* Language Alternates */}
        <link rel="canonical" href={currentUrl} />
        {alternateUrls.map(({ lang, url }) => (
          <link key={lang} rel="alternate" hrefLang={lang} href={url} />
        ))}
        <link
          rel="alternate"
          hrefLang="x-default"
          href={`${baseUrl}${strippedPath}`}
        />
        {/* Open Graph Meta Tags */}
        <meta property="og:site_name" content="WithoutBG" />
        <meta property="og:type" content={type} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={imageUrl} />
        <meta property="og:url" content={currentUrl} />
        <meta property="og:locale" content={currentLanguage} />
        {SUPPORTED_LANGUAGES.map((lang) => (
          <meta
            key={`og-locale-${lang}`}
            property="og:locale:alternate"
            content={lang}
          />
        ))}
        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@withoutbg" />{" "}
        {/* If you have a Twitter account */}
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={imageUrl} />
        {/* Structured Data */}
        {schema && (
          <script type="application/ld+json">{JSON.stringify(schema)}</script>
        )}
        {/* Font Loading - only if loadFonts is true */}
        {loadFonts && (
          <>
            <link
              rel="preconnect"
              href="https://fonts.googleapis.com"
              crossOrigin="anonymous"
            />
            <link
              rel="preconnect"
              href="https://fonts.gstatic.com"
              crossOrigin="anonymous"
            />
            <link
              href="https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&display=swap"
              rel="stylesheet"
              media="print"
              onLoad={(e) => {
                // @ts-ignore
                e.target.media = "all";
              }}
            />
          </>
        )}
        {/* Security Headers */}
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="referrer" content="strict-origin-when-cross-origin" />
        {/* Optional no-index */}
        {noindex && <meta name="robots" content="noindex,nofollow" />}
      </Helmet>
    </HelmetProvider>
  );
};

export default SEOHead;
