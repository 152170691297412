// components/LanguageRoute.tsx
import React, { useEffect, useTransition, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingSpinner from './LoadingSpinner';
import {
    isValidLanguage,
    stripLanguageFromPath,
    addLanguageToPath,
    DEFAULT_LANGUAGE,
    type SupportedLanguage
} from '../utils/languageUtils';

interface LanguageRouteProps {
    children: React.ReactNode;
}

export const LanguageRoute: React.FC<LanguageRouteProps> = ({ children }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { i18n } = useTranslation();
    const [isPending, startTransition] = useTransition();

    useEffect(() => {
        const segments = location.pathname.split('/').filter(Boolean);
        const firstSegment = segments[0];

        if (firstSegment && isValidLanguage(firstSegment)) {
            const detectedLanguage = firstSegment;

            if (detectedLanguage === DEFAULT_LANGUAGE) {
                startTransition(() => {
                    const newPath = stripLanguageFromPath(location.pathname);
                    navigate(newPath + location.search, { replace: true });
                });
            } else if (detectedLanguage !== i18n.language) {
                startTransition(() => {
                    i18n.changeLanguage(detectedLanguage);
                });
            }
        } else {
            if (i18n.language !== DEFAULT_LANGUAGE) {
                startTransition(() => {
                    i18n.changeLanguage(DEFAULT_LANGUAGE);
                });
            }
        }
    }, [location.pathname, i18n]);

    useEffect(() => {
        const segments = location.pathname.split('/').filter(Boolean);
        const firstSegment = segments[0];

        startTransition(() => {
            if (i18n.language === DEFAULT_LANGUAGE) {
                if (isValidLanguage(firstSegment)) {
                    const newPath = stripLanguageFromPath(location.pathname);
                    navigate(newPath + location.search, { replace: true });
                }
            } else if (!isValidLanguage(firstSegment) || firstSegment !== i18n.language) {
                const newPath = addLanguageToPath(location.pathname, i18n.language as SupportedLanguage);
                navigate(newPath + location.search, { replace: true });
            }
        });
    }, [i18n.language, location.pathname, navigate]);

    if (isPending) {
        return <LoadingSpinner />;
    }

    return (
        <Suspense fallback={<LoadingSpinner />}>
            {children}
        </Suspense>
    );
};

export default LanguageRoute;