import { useState, useCallback } from "react";
import config from "../config";
import CaptchaChallenge from "../components/Captcha/Challenge";

export const useCaptchaChallenge = () => {
  const [captchaChallenge, setCaptchaChallenge] =
    useState<CaptchaChallenge | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchCaptchaChallenge = useCallback(async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(`${config.API_URL}/get-captcha-challenge`);
      if (!response.ok) {
        throw new Error("Failed to fetch CAPTCHA challenge");
      }
      const data: CaptchaChallenge = await response.json();
      setCaptchaChallenge(data);
    } catch (error) {
      setError("Error fetching CAPTCHA challenge. Please try again.");
      console.error("Error fetching CAPTCHA challenge:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const resetCaptchaChallenge = useCallback(() => {
    setCaptchaChallenge(null);
    setError(null);
  }, []);

  return {
    captchaChallenge,
    isLoading,
    error,
    fetchCaptchaChallenge,
    resetCaptchaChallenge,
  };
};
