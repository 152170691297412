import React from "react";
import { NavLink } from "react-router-dom";
import { useLocalizedNavigation } from "../../hooks/useLocalizedNavigation";

const Sidebar: React.FC = () => {
  const { createLocalizedLink } = useLocalizedNavigation();
  return (
    <aside className="fixed top-24 w-64 h-full overflow-y-auto py-6 pr-4 hidden lg:block">
      <h3 className="mt-6 mb-2 font-semibold text-gray-500 dark:text-gray-400">
        Output Types
      </h3>
      <ul className="space-y-2">
        <li>
          <NavLink
            to={createLocalizedLink("/documentation/alpha-channel")}
            className={({ isActive }) =>
              `text-gray-700 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white ${
                isActive ? "font-bold" : ""
              }`
            }
          >
            Alpha Channel
          </NavLink>
        </li>
        <li>
          <NavLink
            to={createLocalizedLink("/documentation/transparent-background")}
            className={({ isActive }) =>
              `text-gray-700 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white ${
                isActive ? "font-bold" : ""
              }`
            }
          >
            Transparent Background
          </NavLink>
        </li>
      </ul>
      <h3 className="mt-6 mb-2 font-semibold text-gray-500 dark:text-gray-400">
        API Credit
      </h3>
      <ul className="space-y-2">
        <li>
          <NavLink
            to={createLocalizedLink("/documentation/credits")}
            className={({ isActive }) =>
              `text-gray-700 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white ${
                isActive ? "font-bold" : ""
              }`
            }
          >
            Balance
          </NavLink>
        </li>
      </ul>
    </aside>
  );
};

export default Sidebar;
